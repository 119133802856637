import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import main from './main'
import companies from './companies/companies'
import shifts from './shifts/shifts'
import services from './services/services'
import drivers from './drivers/drivers'
import customers from './customers/customers'
import clients from './clients/clients'
import client from './clients/client'
import publicCustomers from './customers/publicCustomers'
import deliveries from './deliveries/deliveries'
import dashboard from './dashboard/dashboard'
import map from './map/map'
import payment from './payment/payment'
import orders from './orders/orders'
import newOrder from './orders/newOrder'
import orderNotifications from './orders/notifications'
import card from './payment/card/card'
import invite from './invite/invite'
import surcharge from './surcharge/surcharge'
import community from './community/community'
import notifications from './notifications/notifications'
import invoices from './invoices/invoices'
import resetPassword from './resetPassword/resetPassword'
import operationStatus from './operationStatus/operationStatus'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    main,
    companies,
    shifts,
    services,
    drivers,
    customers,
    deliveries,
    map,
    clients,
    client,
    dashboard,
    publicCustomers,
    payment,
    card,
    newOrder,
    orders,
    orderNotifications,
    invite,
    surcharge,
    community,
    notifications,
    invoices,
    resetPassword,
    operationStatus
  }
})
