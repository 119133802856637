<template>
  <div class="filter__wrapper">
    <div class="filter__header">
      <div class="filter__title">← Map Filters</div>
    </div>
    <div class="filter__main">
      <div class="filter__row">
        <div class="field__label">
          <div class="field__title">Date</div>
          <Calendar v-model="range" is-range>
            <template v-slot="{inputValue, inputEvents}">
              <input class="field__input" :value="inputValue.start + ' - ' + inputValue.end" v-on="inputEvents.start" />
            </template>
          </Calendar>
        </div>
      </div>
      <div class="filter__row">
        <Select v-model="shift" :options="shifts" label="Shifts" placeholder="Please select shifts" />
      </div>
      <div class="filter__row">
        <Select v-model="service" :options="services" label="Services" placeholder="Please select services" />
      </div>
      <div class="filter__row">
        <Select v-model="customer" :options="customers" label="Customers" placeholder="Please select customers" />
      </div>
      <div class="filter__row">
        <Select v-model="status" :options="statuses" label="Statuses" placeholder="Please select status" />
      </div>
    </div>
    <div class="filter__footer">
      <div class="button">Cancel</div>
      <div class="button button--fill">Apply</div>
    </div>
  </div>
</template>

<script>
import Calendar from 'v-calendar/lib/components/date-picker.umd'
import Select from '@/components/Select'

export default {
  name: 'MapFilter',
  components: {
    Calendar,
    Select
  },
  data: () => ({
    range: {
      start: '',
      end: ''
    },
    shift: '',
    shifts: ['North Shift1', 'North Shift2', 'North Shift3', 'North Shift4', 'North Shift5'],
    service: '',
    services: ['North Shift1', 'North Shift2', 'North Shift3', 'North Shift4', 'North Shift5'],
    customer: '',
    customers: ['North Shift1', 'North Shift2', 'North Shift3', 'North Shift4', 'North Shift5'],
    status: '',
    statuses: ['North Shift1', 'North Shift2', 'North Shift3', 'North Shift4', 'North Shift5']
  })
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
