<template>
  <div class="params__wrapper">
    <div class="params__header">
      <div class="params__title">Map</div>
      <div class="params__button button button--fill" @click="showMapFilter">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.875 3.75H4.12501C3.91789 3.75 3.75 3.91789 3.75 4.12501V5.99999C3.74991 6.10639 3.79504 6.20781 3.87412 6.279L7.5 9.54224V13.875C7.49991 14.0821 7.66772 14.25 7.87483 14.2502C7.93308 14.2502 7.99054 14.2366 8.04264 14.2106L10.2926 13.0856C10.4198 13.0221 10.5001 12.8921 10.5 12.75V9.54224L14.1259 6.27975C14.2052 6.20841 14.2503 6.10665 14.25 5.99999V4.12501C14.25 3.91789 14.0821 3.75 13.875 3.75Z"
            fill="white"
          />
        </svg>
        <span>Filters</span>
      </div>
    </div>
    <div class="params__main">
      <div class="params__date">13 September 2020</div>
      <div class="main__wrapper">
        <DeliveryCard />
        <DeliveryCard />
        <DeliveryCard />
        <DeliveryCard />
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryCard from './DeliveryCard'

export default {
  name: 'MapParams',
  components: {DeliveryCard},
  methods: {
    showMapFilter() {
      this.$root.$emit('ShowSidebar', ['MapFilter', 'left: 0'])
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
