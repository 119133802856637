<template>
  <div class="sidebar__main">
    <div class="sidebar__title">
      <span v-if="data">Edit - {{ data.businessName }}</span>
      <span v-else>Add New Public Clients</span>
    </div>
    <div class="sidebar__main-inner">
      <div class="sidebar__row">
        <div class="sidebar__row-title">Basic Data</div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.businessName.$model && $v.sendData.businessName.$dirty,
              'field__label--invalid': $v.sendData.businessName.$dirty && !$v.sendData.businessName.required
            }"
          >
            <div class="field__title">Business Name</div>
            <input
              v-model="$v.sendData.businessName.$model"
              class="field__input"
              type="text"
              placeholder="Business Name"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.quickBooksName.$model && $v.sendData.quickBooksName.$dirty,
              'field__label--invalid': $v.sendData.quickBooksName.$dirty && !$v.sendData.quickBooksName.required
            }"
          >
            <div class="field__title">Quick Books Name</div>
            <input
              v-model="$v.sendData.quickBooksName.$model"
              class="field__input"
              type="text"
              placeholder="Quick Books Name"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.contactEmail.$model && $v.sendData.contactEmail.email && $v.sendData.contactEmail.$dirty,
              'field__label--invalid': $v.sendData.contactEmail.$dirty && !$v.sendData.contactEmail.email
            }"
          >
            <div class="field__title">Contact Email</div>
            <input
              v-model="$v.sendData.contactEmail.$model"
              class="field__input"
              type="text"
              placeholder="Contact Email"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.firstName.$model && $v.sendData.firstName.$dirty
            }"
          >
            <div class="field__title">First Name</div>
            <input v-model="$v.sendData.firstName.$model" class="field__input" type="text" placeholder="First Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.lastName.$model && $v.sendData.lastName.$dirty
            }"
          >
            <div class="field__title">Last Name</div>
            <input v-model="$v.sendData.lastName.$model" class="field__input" type="text" placeholder="Last Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.fathersName.$model && $v.sendData.fathersName.$dirty
            }"
          >
            <div class="field__title">Father's Name</div>
            <input
              v-model="$v.sendData.fathersName.$model"
              class="field__input"
              type="text"
              placeholder="Father's Name"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.nickName.$model && $v.sendData.nickName.$dirty
            }"
          >
            <div class="field__title">Nick Name</div>
            <input v-model="$v.sendData.nickName.$model" class="field__input" type="text" placeholder="Nick Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.middleInitial.$model && $v.sendData.middleInitial.$dirty
            }"
          >
            <div class="field__title">Middle Initial</div>
            <input
              v-model="$v.sendData.middleInitial.$model"
              v-mask="'AA'"
              class="field__input field__input--uppercase"
              type="text"
              placeholder="MI"
            />
          </label>
        </div>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Contact Info</div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.cellPhone.$model && $v.sendData.cellPhone.$dirty && $v.sendData.cellPhone.phoneReg,
              'field__label--invalid': $v.sendData.cellPhone.$dirty && !$v.sendData.cellPhone.phoneReg
            }"
          >
            <div class="field__title">Cell Phone</div>
            <input
              v-model="$v.sendData.cellPhone.$model"
              v-mask="'### ### ####'"
              class="field__input"
              type="tel"
              placeholder="Cell Phone"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.homePhone.$model && $v.sendData.homePhone.$dirty && $v.sendData.homePhone.phoneReg,
              'field__label--invalid': $v.sendData.homePhone.$dirty && !$v.sendData.homePhone.phoneReg
            }"
          >
            <div class="field__title">Home Phone</div>
            <input
              v-model="$v.sendData.homePhone.$model"
              v-mask="'### ### ####'"
              class="field__input"
              type="tel"
              placeholder="Home Phone"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.businessPhone.$model &&
                $v.sendData.businessPhone.$dirty &&
                $v.sendData.businessPhone.phoneReg,
              'field__label--invalid': $v.sendData.businessPhone.$dirty && !$v.sendData.businessPhone.phoneReg
            }"
          >
            <div class="field__title">Business Phone</div>
            <input
              v-model="$v.sendData.businessPhone.$model"
              v-mask="'### ### ####'"
              class="field__input"
              type="tel"
              placeholder="Business Phone"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.accountingEmail.$model &&
                $v.sendData.accountingEmail.$dirty &&
                $v.sendData.accountingEmail.email,
              'field__label--invalid': $v.sendData.accountingEmail.$dirty && !$v.sendData.accountingEmail.email
            }"
          >
            <div class="field__title">Accounting E-mail</div>
            <input
              v-model="$v.sendData.accountingEmail.$model"
              class="field__input"
              type="text"
              placeholder="Accounting E-mail"
            />
          </label>
        </div>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Address Info</div>
        <div class="sidebar__row-main sidebar__row-main--third">
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.lat.$model && $v.sendData.lat.$dirty
            }"
          >
            <div class="field__title">Latitude</div>
            <input v-model="$v.sendData.lat.$model" class="field__input" type="text" placeholder="Latitude" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.lng.$model && $v.sendData.lng.$dirty
            }"
          >
            <div class="field__title">Longitude</div>
            <input v-model="$v.sendData.lng.$model" class="field__input" type="text" placeholder="Longitude" />
          </label>
          <button class="button button--fill" @click="openMap">Open map</button>
        </div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.country.$model && $v.sendData.country.$dirty
            }"
          >
            <div class="field__title">Country</div>
            <input v-model="$v.sendData.country.$model" class="field__input" type="text" placeholder="Country" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.province.$model && $v.sendData.province.$dirty
            }"
          >
            <div class="field__title">Province</div>
            <input v-model="$v.sendData.province.$model" class="field__input" type="text" placeholder="Province" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.city.$model && $v.sendData.city.$dirty
            }"
          >
            <div class="field__title">City</div>
            <input v-model="$v.sendData.city.$model" class="field__input" type="text" placeholder="City" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.postalCode.$model && $v.sendData.postalCode.$dirty,
              'field__label--invalid': $v.sendData.postalCode.$dirty && !$v.sendData.postalCode.minLength
            }"
          >
            <div class="field__title">Postal Code</div>
            <input v-model="$v.sendData.postalCode.$model" class="field__input" type="text" placeholder="Postal Code" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.northSouth.$model && $v.sendData.northSouth.$dirty
            }"
          >
            <el-switch
              v-model="$v.sendData.northSouth.$model"
              active-text="North"
              inactive-text="South"
              class="el-switch--field"
            />
          </label>
          <label
            class="field__label field__label--full"
            :class="{
              'field__label--filled': $v.sendData.address.$model && $v.sendData.address.$dirty
            }"
          >
            <div class="field__title">Address</div>
            <input v-model="$v.sendData.address.$model" class="field__input" type="text" placeholder="Address" />
          </label>
        </div>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Attachments</div>
        <div class="sidebar__row-main">
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.notes.$model && $v.sendData.notes.$dirty
            }"
          >
            <div class="field__title">Notes</div>
            <textarea
              v-model="$v.sendData.notes.$model"
              class="field__text"
              placeholder="Start typing here..."
            ></textarea>
          </label>
          <div v-if="(data && data.attachmentPath) || photo" class="field__label--image">
            <el-image
              v-if="data.attachmentPath && !photo"
              :src="data.attachmentPath"
              :preview-src-list="[data.attachmentPath]"
              fit="cover"
            />
            <el-image v-if="photo" :src="photo" :preview-src-list="[photo]" fit="cover" />
          </div>
          <FilesUpload v-model="file" />
        </div>
      </div>
      <div class="sidebar__row sidebar__row--last">
        <div class="sidebar__row-title">User Info</div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label field__label--full"
            :class="{
              'field__label--filled':
                $v.sendData.password.$model && $v.sendData.password.$dirty && $v.sendData.password.minLength
            }"
          >
            <div class="field__title">Password</div>
            <input v-model="$v.sendData.password.$model" class="field__input" type="text" placeholder="Password" />
          </label>
          <label
            class="field__label field__label--sides"
            :class="{
              'field__label--filled': $v.sendData.isPublic.$model && $v.sendData.isPublic.$dirty
            }"
          >
            <div class="field__title">Public</div>
            <el-switch v-model="$v.sendData.isPublic.$model" />
          </label>
          <label
            class="field__label field__label--sides"
            :class="{
              'field__label--filled': $v.sendData.user.isActive.$model && $v.sendData.user.isActive.$dirty
            }"
          >
            <div class="field__title">Active</div>
            <el-switch v-model="$v.sendData.user.isActive.$model" />
          </label>
          <label
            class="field__label field__label--sides"
            :class="{
              'field__label--filled': $v.sendData.user.communityBishop.$model && $v.sendData.user.communityBishop.$dirty
            }"
          >
            <div class="field__title">Community Admin</div>
            <el-switch v-model="$v.sendData.user.communityBishop.$model" />
          </label>
          <label
            class="field__label field__label--sides"
            :class="{
              'field__label--filled':
                $v.sendData.isOutsideOperativeArea.$model && $v.sendData.isOutsideOperativeArea.$dirty
            }"
          >
            <div class="field__title">Outside Operative Area</div>
            <el-switch v-model="$v.sendData.isOutsideOperativeArea.$model" />
          </label>
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div class="button button--fill" @click="createCustomer">Save</div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, email, minLength, helpers} from 'vuelidate/lib/validators'
import _ from 'lodash'
import FilesUpload from './FilesUpload'
const phoneReg = helpers.regex('phoneReg', /^[2-9]\d{2} \d{3} \d{4}$/)
import Map from './MapPopup'

export default {
  name: 'AddClient',
  components: {FilesUpload},
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    sendData: {
      firstName: '',
      lastName: '',
      contactEmail: '',
      fathersName: '',
      businessName: '',
      middleInitial: '',
      nickName: '',
      quickBooksName: '',
      accountingEmail: '',
      cellPhone: '',
      homePhone: '',
      businessPhone: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
      lat: '',
      lng: '',
      northSouth: false,
      password: '',
      user: {isActive: true, communityBishop: false},
      isPublic: true,
      companyName: '',
      notes: '',
      isOutsideOperativeArea: false
    },
    file: null,
    photo: null
  }),
  watch: {
    file(file) {
      if (!file) {
        this.photo = null
        return
      }
      this.photo = window.URL.createObjectURL(file)
    }
  },
  created() {
    if (this.data) {
      _.assign(this.sendData, this.data)
    }
    if (!this.sendData.user?.id) {
      this.sendData.user = {isActive: true, communityBishop: false}
    }
  },
  mounted() {
    this._keyListener = function(e) {
      if (event.keyCode === 27) {
        e.preventDefault()
        this.$root.$emit('ShowSidebar', null)
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
    this.$root.$on('setMapParams', params => {
      this.sendData.lat = params.lat
      this.sendData.lng = params.lng
    })
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener)
  },
  methods: {
    async createCustomer() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const sendData = {
        firstName: this.sendData.firstName,
        lastName: this.sendData.lastName,
        contactEmail: this.sendData.contactEmail,
        fathersName: this.sendData.fathersName,
        businessName: this.sendData.businessName,
        quickBooksName: this.sendData.quickBooksName,
        accountingEmail: this.sendData.accountingEmail,
        cellPhone: this.sendData.cellPhone,
        homePhone: this.sendData.homePhone,
        businessPhone: this.sendData.businessPhone,
        address: this.sendData.address,
        city: this.sendData.city,
        province: this.sendData.province,
        postalCode: this.sendData.postalCode,
        country: this.sendData.country,
        lat: this.sendData.lat,
        lng: this.sendData.lng,
        northSouth: this.sendData.northSouth,
        isPublic: this.sendData.isPublic,
        notes: this.sendData.notes,
        middleInitial: this.sendData.middleInitial,
        nickName: this.sendData.nickName,
        isOutsideOperativeArea: this.sendData.isOutsideOperativeArea
      }
      if (this.sendData.user?.id || this.sendData.password) {
        sendData.user = {}
        sendData.user.communityBishop = this.sendData.user.communityBishop
        sendData.user.isActive = this.sendData.user.isActive
      }

      if (this.sendData.password) {
        sendData.user.password = this.sendData.password
      }
      try {
        if (this.data) {
          sendData.id = this.data.id
          await this.$store.dispatch('clients/update', sendData)
          if (this.file && !this.data.attachmentPath) {
            await this.$store.dispatch('clients/uploadPhoto', {file: this.file, id: this.data.id})
          } else if (this.file && this.data.attachmentPath) {
            await this.$store.dispatch('clients/removePhoto', this.data.id)
            await this.$store.dispatch('clients/uploadPhoto', {file: this.file, id: this.data.id})
          }
        } else {
          const resp = await this.$store.dispatch('clients/create', sendData)
          if (this.file) {
            await this.$store.dispatch('clients/uploadPhoto', {file: this.file, id: resp.data.customer.id})
          }
        }
        this.$store.commit('clients/reset')
        await this.$store.dispatch('clients/fetch')
        this.$root.$emit('ShowSidebar', null)
      } catch (e) {
        if (e.response.data.error) {
          alert(e.response.data.error)
        } else {
          alert(e.response.data.errors[0].error)
        }
      }
    },
    openMap() {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 600}

      this.$modal.display(
        Map,
        {},
        {
          name: 'Map',
          transition: 'none',
          ...modalHeight,
          overlayTransition: 'none',
          classes: 'modal'
        }
      )
    }
  },
  validations: {
    sendData: {
      firstName: {},
      lastName: {},
      contactEmail: {email},
      fathersName: {},
      nickName: {},
      middleInitial: {},
      businessName: {required},
      quickBooksName: {required},
      accountingEmail: {email},
      cellPhone: {phoneReg},
      homePhone: {phoneReg},
      businessPhone: {phoneReg},
      address: {},
      city: {},
      province: {},
      postalCode: {minLength: minLength(5)},
      country: {},
      lat: {required},
      lng: {required},
      northSouth: {},
      password: {minLength: minLength(8)},
      user: {
        isActive: {},
        communityBishop: {}
      },
      isPublic: {},
      isOutsideOperativeArea: {},
      companyName: {},
      notes: {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
