import axios from 'axios'
import _ from 'lodash'
import exports from './exports'
export default {
  namespaced: true,

  state: {
    deliveries: [],
    markers: [],
    delivery: {},
    pagination: {},
    page: 1,
    // FILTER PARAMS
    searchParams: {},
    filterParamsLocal: {range: [null, null], date: [{value: 'today', label: 'Today'}]},
    filterParamsSend: {'filters[date]': 'today'},
    filterParamsOriginal: {
      dateRange: [],
      status: {},
      date: [],
      name: null,
      range: [null, null]
    },
    // CUSTOMERS
    pickup: null,
    dropOffs: [{businessName: null}],
    billing: null
  },
  mutations: {
    set(state, deliveries) {
      state.deliveries = _.unionBy(state.deliveries, deliveries, 'id')
    },
    change(state, item) {
      if (typeof item === 'object') {
        state.deliveries = state.deliveries.map(delivery => {
          if (delivery.id === item.id) {
            return _.assign(delivery, item, item.customerInfo)
          } else {
            return delivery
          }
        })
      } else if (typeof item === 'number') {
        const position = state.deliveries.findIndex(sh => sh.id === item)
        state.deliveries.splice(position, 1)
        state.pagination.total--
      }
    },
    setDelivery(state, delivery) {
      state.delivery = delivery
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.deliveries = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    },
    // FILTER PARAMS
    filterParamsLocal(state, params) {
      state.filterParamsLocal = params
    },
    filterParamsSend(state, params) {
      state.filterParamsSend = params
    },
    searchParams(state, params) {
      state.searchParams = {'filters[businessName]': params}
    },
    resetFilterParams(state) {
      state.filterParams = null
    },
    setPickup(state, pickup) {
      state.pickup = pickup
    },
    setBilling(state, billing) {
      state.billing = billing
    },
    setDropOff(state, customer) {
      state.dropOffs.splice(customer.index, customer.index + 1, customer.dropOff)
    },
    resetDelivery(state) {
      state.pickup = null
      state.dropOffs = [{businessName: null}]
      state.billing = null
    }
  },
  actions: {
    async fetch({commit, getters}) {
      let page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const deliveries = await axios.get(url + '/deliveries/', {
          params: {page, perPage: 24, ...getters.filterParamsSend, ...getters.searchParams}
        })
        commit('pagination', deliveries.data.pagination)
        commit('set', deliveries.data.deliveries)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchDelivery({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const delivery = await axios.get(url + '/deliveries/' + id)
        commit('setDelivery', delivery.data.delivery)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async create({commit, dispatch}, delivery) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.post(url + '/deliveries/', delivery)
        commit('reset')
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async update({commit, dispatch}, {delivery, id}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + '/deliveries/' + id, delivery)
        delivery.id = id
        commit('reset')
        dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async delete({commit, dispatch}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.delete(url + '/deliveries/' + id)
        commit('reset')
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async archive({commit, dispatch}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.delete(url + '/deliveries/' + id + '/archive')
        commit('reset')
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async updateStatus({commit, dispatch}, {id, status}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/deliveries/' + id + '/status', {status})
        commit('reset')
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  },
  getters: {
    deliveries: s => s.deliveries,
    delivery: s => s.delivery,
    pagination: s => s.pagination,
    page: s => s.page,
    // FILTER PARAMS
    filterParamsOriginal: s => s.filterParamsOriginal,
    filterParamsLocal: s => s.filterParamsLocal,
    filterParamsSend: s => s.filterParamsSend,
    searchParams: s => s.searchParams
  },
  modules: {
    exports
  }
}
