<template>
  <div class="invite__layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'InviteLayout',
  computed: {
    error() {
      return this.$store.getters.error
    }
  },
  watch: {
    error(error) {
      this.$error(error.data.error)
    }
  }
}
</script>
