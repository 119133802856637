import axios from 'axios'

export default {
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
    error: {},
    decryptToken: {}
  },
  mutations: {
    auth_success(state, token) {
      state.status = 'success'
      state.token = token
    },
    setUser(state, user) {
      state.user = user
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
    setError(state, error) {
      if (error.response.status === 401 || error.response.status === 419) return
      state.error = error.response
    }
  },
  actions: {
    async login({commit}, cus) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const auth = await axios.post(url + '/users/login', cus)
        const token = auth.data.token
        const user = auth.data.user

        localStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        commit('auth_success', token)
        commit('setUser', user)
      } catch (e) {
        commit('setError', e)
        localStorage.removeItem('token')
        throw e
      }
    },
    logout({commit}) {
      return new Promise(resolve => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
    token: s => s.token,
    error: s => s.error,
    decryptToken: s => {
      if (s.token) {
        return JSON.parse(atob(s.token.split('.')[1]))
      } else {
        return {}
      }
    }
  }
}
