import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    exports: [],
    pagination: {},
    page: 1,
    csv: null
  },
  mutations: {
    set(state, exports) {
      state.exports = _.unionBy(state.exports, exports, 'id')
    },
    setCSV(state, csv) {
      state.csv = csv
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.exports = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    }
  },
  actions: {
    async create({commit}, exportData) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.post(url + '/exports/', exportData)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetch({commit, getters}) {
      let page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const exports = await axios.get(url + '/exports/', {
          params: {page}
        })
        commit('pagination', exports.data.pagination)
        commit('set', exports.data.exports)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async download({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const csv = await axios.get(url + `/exports/${id}/download/`)
        commit('setCSV', csv.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async cancel({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const {data} = await axios.patch(url + `/exports/${id}/cancel/`)
        return data
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async restart({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const {data} = await axios.patch(url + `/exports/${id}/restart/`)
        return data
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  }
}
