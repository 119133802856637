<template>
  <div class="main-layout">
    <!-- <Navbar /> -->
    <div class="page__wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
// import Navbar from '@/components/Navbar'

export default {
  name: 'MainLayout',
  // components: {Navbar}
}
</script>
