import axios from 'axios'

export default {
  namespaced: true,

  state: {
    notifications: []
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications
    },
    setOldNotifications(state, notifications) {
      state.notifications = notifications.map(n => {
        if (n.event === 'delivery-order.created') n.status = 'PENDING'
        else if (n.event === 'delivery-order.approved') n.status = 'APPROVED'
        else if (n.event === 'delivery-order.declined') n.status = 'DECLINED'
        else if (n.event === 'delivery-order.waiting-for-payment') n.status = 'WaitingForPayment'
        else if (n.event === 'delivery-order.canceled') n.status = 'CANCELED'
        return {
          ...n,
          ...n.payload
        }
      })
    },
    setCreatedNotification(state, notification) {
      state.notifications.unshift({
        status: 'PENDING',
        wasRead: false,
        ...notification,
        id: notification.notificationID
      })
    },
    setApprovalNotification(state, notification) {
      state.notifications.unshift({
        status: 'APPROVED',
        wasRead: false,
        ...notification,
        id: notification.notificationID
      })
    },
    setCanceledNotification(state, notification) {
      state.notifications.unshift({
        status: 'CANCELED',
        wasRead: false,
        ...notification,
        id: notification.notificationID
      })
    },
    setDeclinedNotification(state, notification) {
      state.notifications.unshift({
        status: 'DECLINED',
        wasRead: false,
        ...notification,
        id: notification.notificationID
      })
    },
    setWaitingForPaymentNotification(state, notification) {
      state.notifications.unshift({
        status: 'WaitingForPayment',
        wasRead: false,
        ...notification,
        id: notification.notificationID
      })
    }
  },
  actions: {
    async getNotifications({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const notifications = await axios.get(url + '/notifications')
        commit('setOldNotifications', notifications.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async readNotification({commit, state}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const respNotification = await axios.patch(url + `/notifications/${id}/read`)
        if (respNotification.data.success) {
          const readedNotification = state.notifications.find(n => n.id === id)
          readedNotification.wasRead = true
        }
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  }
}
