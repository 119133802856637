import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {layout: 'login'},
    component: () => import('@/views/Login')
  },
  {
    path: '/noAccess',
    name: 'noAccess',
    meta: {layout: 'login'},
    component: () => import('@/views/noAccess')
  },
  {
    path: '/',
    name: 'Home',
    meta: {layout: 'admin', access: ['super_admin', 'company_admin', 'driver', 'customer'], auth: true},
    component: () => import('@/views/Home')
  },
  {
    path: '/companies',
    name: 'Companies',
    meta: {layout: 'superAdmin', access: ['super_admin'], auth: true},
    component: () => import('@/views/SuperAdmin/Companies')
  },
  {
    path: '/clients',
    name: 'Clients',
    meta: {layout: 'superAdmin', access: ['super_admin'], auth: true},
    component: () => import('@/views/SuperAdmin/Clients')
  },
  {
    path: '/users',
    name: 'Users',
    redirect: '/users/drivers',
    meta: {layout: 'admin', access: ['company_admin'], auth: true},
    component: () => import('@/views/Admin/Users'),
    children: [
      {
        path: 'drivers',
        name: 'Drivers',
        meta: {layout: 'admin', access: ['company_admin'], auth: true, parent: 'Users'},
        component: () => import('@/views/Admin/Drivers')
      },
      {
        path: 'customers',
        name: 'Customers',
        meta: {layout: 'admin', access: ['company_admin'], auth: true, parent: 'Users'},
        component: () => import('@/views/Admin/Customers')
      },
      {
        path: 'clients',
        name: 'PublicCustomers',
        meta: {layout: 'admin', access: ['company_admin'], auth: true, parent: 'Users'},
        component: () => import('@/views/Admin/PublicCustomers')
      },
      {
        path: 'enterprise',
        name: 'EnterpriseCustomers',
        meta: {layout: 'admin', access: ['company_admin'], auth: true, parent: 'Users'},
        component: () => import('@/views/Admin/EnterpriseCustomers')
      }
    ]
  },
  {
    path: '/users/customers/:id',
    name: 'Customer',
    meta: {layout: 'admin', access: ['company_admin'], auth: true},
    component: () => import('@/views/Admin/Customer')
  },
  {
    path: '/shifts',
    name: 'Shifts',
    meta: {layout: 'admin', access: ['company_admin'], auth: true},
    component: () => import('@/views/Admin/Shifts')
  },
  {
    path: '/services',
    name: 'Services',
    meta: {layout: 'admin', access: ['company_admin'], auth: true},
    component: () => import('@/views/Admin/Services')
  },
  {
    path: '/deliveries',
    name: 'Deliveries',
    meta: {layout: 'admin', access: ['company_admin', 'driver'], auth: true},
    component: () => import('@/views/Admin/Deliveries')
  },
  {
    path: '/deliveries/export',
    name: 'ExportDeliveries',
    meta: {layout: 'admin', access: ['company_admin', 'driver'], auth: true},
    component: () => import('@/views/Admin/Deliveries/ExportDeliveries')
  },
  {
    path: '/map',
    name: 'Map',
    meta: {layout: 'admin', access: ['company_admin', 'driver'], auth: true},
    component: () => import('@/views/Admin/Map')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {layout: 'admin', access: ['company_admin'], auth: true},
    component: () => import('@/views/Admin/Dashboard')
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: {layout: 'admin', access: ['company_admin', 'driver'], auth: true},
    component: () => import('@/views/Admin/Orders')
  },
  {
    path: '/orders/:id',
    name: 'AdminOrderPage',
    meta: {layout: 'admin', access: ['company_admin', 'driver'], auth: true, parent: 'Orders'},
    component: () => import('@/views/Admin/Orders/OrderPage')
  },
  {
    path: '/admin/invoices',
    name: 'FinanceInvoicesAdmin',
    meta: {layout: 'admin', access: ['company_admin'], auth: true},
    component: () => import('@/views/Admin/Invoices')
  },
  {
    path: '/admin/invoices/:id',
    name: 'FinanceInvoiceAdmin',
    meta: {layout: 'admin', access: ['company_admin'], auth: true},
    component: () => import('@/views/Admin/InvoicePage')
  },
  {
    path: '/public',
    name: 'PublicCustomer',
    redirect: '/public/orders',
    meta: {layout: 'client', access: ['customer'], auth: true},
    component: () => import('@/views/Client'),
    children: [
      {
        path: 'orders',
        name: 'PublicOrders',
        meta: {layout: 'client', access: ['customer'], auth: true, parent: 'Clients'},
        component: () => import('@/views/Client/Orders')
      },
      {
        path: 'orders/:id',
        name: 'OrderPage',
        meta: {layout: 'client', access: ['customer'], auth: true, parent: 'Clients'},
        component: () => import('@/views/Client/OrderPage')
      },
      {
        path: 'new-order',
        name: 'NewOrder',
        meta: {layout: 'client', access: ['customer'], auth: true, parent: 'Clients'},
        component: () => import('@/views/Client/NewOrder')
      }
    ]
  },
  {
    path: '/public/account',
    name: 'Account',
    meta: {layout: 'client', access: ['customer'], auth: true},
    component: () => import('@/views/Client/Account')
  },
  {
    path: '/invite/:token',
    name: 'Invite',
    meta: {layout: 'Invite'},
    component: () => import('@/views/Invite')
  },
  {
    path: '/community',
    name: 'Community',
    meta: {layout: 'client', parent: 'Community', access: ['customer'], auth: true},
    component: () => import('@/views/Client/Community')
  },
  {
    path: '/community/:id',
    name: 'Post',
    meta: {layout: 'client', parent: 'Community', access: ['customer'], auth: true},
    component: () => import('@/views/Client/Community/PostPage')
  },
  {
    path: '/directory',
    name: 'Directory',
    meta: {layout: 'client', parent: 'Community', access: ['customer'], auth: true},
    component: () => import('@/views/Client/Directory')
  },
  {
    path: '/directory/:id',
    name: 'DirectoryCustomer',
    meta: {layout: 'client', access: ['customer'], auth: true},
    component: () => import('@/views/Client/Directory/CustomerPage')
  },
  {
    path: '/enterprise',
    name: 'EnterpriseCustomer',
    redirect: '/enterprise/orders',
    meta: {layout: 'enterprise', access: ['customer_enterprise'], auth: true},
    component: () => import('@/views/Enterprise'),
    children: [
      {
        path: 'orders',
        name: 'EnterpriseOrders',
        meta: {layout: 'enterprise', access: ['customer_enterprise'], auth: true, parent: 'Enterprise'},
        component: () => import('@/views/Enterprise/Orders')
      },
      {
        path: 'orders/:id',
        name: 'OrderPageEnterprise',
        meta: {layout: 'enterprise', access: ['customer_enterprise'], auth: true, parent: 'Enterprise'},
        component: () => import('@/views/Enterprise/OrderPage')
      },
      {
        path: 'new-order',
        name: 'NewOrderEnterprise',
        meta: {layout: 'enterprise', access: ['customer_enterprise'], auth: true, parent: 'Enterprise'},
        component: () => import('@/views/Enterprise/NewOrder')
      }
    ]
  },
  {
    path: '/enterprise/account',
    name: 'AccountEnterprice',
    meta: {layout: 'enterprise', access: ['customer_enterprise'], auth: true},
    component: () => import('@/views/Enterprise/Account')
  },
  {
    path: '/enterprise/invoices',
    name: 'FinanceInvoices',
    meta: {layout: 'enterprise', access: ['customer_enterprise'], auth: true},
    component: () => import('@/views/Enterprise/Invoices')
  },
  {
    path: '/enterprise/invoices/:id',
    name: 'FinanceInvoice',
    meta: {layout: 'enterprise', access: ['customer_enterprise'], auth: true},
    component: () => import('@/views/Enterprise/InvoicePage')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn
  let role = null
  let user = null
  if (store.getters.token) {
    user = JSON.parse(atob(store.getters.token.split('.')[1]))
    role = user.role
  }
  if (user?.customer?.isEnterprise) {
    role = 'customer_enterprise'
  }
  const requiredAuth = to.matched.some(record => record.meta.auth)
  const access = to.meta.access || []
  const entrance = access.some(acc => acc === role)
  if (requiredAuth && !isLoggedIn) {
    next('/login')
  } else if (requiredAuth && !entrance) {
    next('/login')
  } else {
    next()
  }
})

export default router
