<template>
  <div class="client-layout" :class="{'bg-white': parent === 'Community'}">
    <Navbar v-if="component !== 'NewOrder' || !mobile" />
    <router-view />
    <Sidebar
      v-if="sidebarName"
      :component-name="sidebarName"
      :component-styles="componentStyles"
      :sidebar-styles="sidebarStyles"
      :sidebar-position="sidebarPosition"
      :component-data="componentData"
    />
  </div>
</template>

<script>
import Navbar from '@/views/Client/Navbar'
import Sidebar from '@/components/Sidebar'

export default {
  name: 'ClientLayout',
  components: {
    Navbar,
    Sidebar
  },
  data: () => ({
    sidebarName: null,
    sidebarStyles: null,
    sidebarRotation: 'right',
    componentStyles: null,
    animationName: 'sidebar',
    componentData: null,
    mobile: false,
    key: 0
  }),
  computed: {
    component() {
      return this.$route.name
    },
    parent() {
      return this.$route.meta.parent
    },
    error() {
      return this.$store.getters.error
    }
  },
  watch: {
    sidebarName(name) {
      if (name) {
        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
      } else {
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
    },
    async error(error) {
      let err = null
      console.log(error)
      if (error.data.error === 'CompanyNotOperationalError') {
        err = error.data.details
        await this.$store.dispatch('operationStatus/getBlockInfo')
        this.$router.push('/public/orders')
      } else if (error.data.error) err = error.data.error
      else if (error?.data?.errors[0]?.error) err = error.data.errors[0].error
      this.$error(err)
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.mobile = document.documentElement.clientWidth <= 480
    this.$root.$on('ShowSidebar', params => {
      setTimeout(() => {
        if (params === null) {
          this.sidebarName = params
        } else if (typeof params === 'string') {
          this.sidebarName = params
          this.componentData = null
        } else if (typeof params === 'object') {
          this.sidebarName = params.sidebarName
          this.sidebarStyles = params.sidebarStyles
          this.componentStyles = params.componentStyles
          this.componentData = params.componentData
          this.animationName = !params.animationName ? 'sidebar' : params.animationName
        }
        this.sidebarPosition = !params?.sidebarPosition ? 'right' : params.sidebarPosition
      }, 0)
      this.key += 1
    })
  }
}
</script>
