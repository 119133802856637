<template>
  <div class="modal__wrapper">
    <div class="modal__header">
      <button class="icon-button icon-button--round" @click="$modal.hide('SearchPublicCustomerModal')">
        <i class="ri-close-circle-fill"></i>
      </button>
      <div class="modal__title">Choose Pickup</div>
      <button class="button button--fill" :class="{'button--fill--grey': !selectedCustomer}" @click="done">Done</button>
    </div>
    <div class="modal__main">
      <div class="modal-search__label">
        <div v-if="type === 'pickup'" class="icon-button icon-button--round icon-button--blue">
          <i class="ri-arrow-up-circle-line"></i>
        </div>
        <div v-if="type === 'dropOff'" class="icon-button icon-button--round icon-button--orange">
          <i class="ri-arrow-down-circle-line"></i>
        </div>
        <div v-if="type === 'billing'" class="icon-button icon-button--round icon-button--blue">
          <i class="ri-money-dollar-circle-line"></i>
        </div>
        <input
          ref="searchField"
          type="text"
          class="modal-search__input"
          placeholder="Start typing business name"
          @input="search"
        />
      </div>
      <div v-if="customers.length" class="modal-search__list">
        <div
          v-for="customer of customers"
          :key="customer.id"
          class="modal-search__item"
          :class="{'modal-search__item--active': customer.active}"
          @click="select(customer)"
        >
          <div class="modal-search__item-icon">
            <i class="ri-checkbox-circle-fill"></i>
          </div>
          <div class="modal-search__item-customer">
            <!-- <div class="modal-search__item-name">{{ $dots(customer.businessName, maxCharacters) }}</div> -->
            <div class="modal-search__item-name">
              <span v-if="customer.fathersName">{{ customer.fathersName }}’ </span>
              <span>{{ customer.firstName }} </span>
              <span>{{ customer.middleInitial }} </span>
              <span>{{ customer.lastName }} </span>
              <span v-if="customer.nickName">({{ customer.nickName }})</span>
            </div>
            <div class="modal-search__item-name">
              <span>{{ customer.businessName }}</span>
            </div>
            <!-- <div class="modal-search__item-fname">
              <span>(FatherName)</span>
              <span>{{ $dots(customer.fathersName, 18) }}</span>
            </div> -->
            <div class="modal-search__item-address">
              <span>Address: </span>
              <span>{{ customer.address }} {{ customer.city }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions, mapMutations} from 'vuex'
import _ from 'lodash'

export default {
  name: 'SearchPublicCustomerModal',
  props: {
    type: {
      type: String,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selectedCustomer: null
    }
  },
  computed: {
    ...mapGetters({
      customers: 'customers/searchCustomers'
    }),
    ...mapState({
      pickup: s => s.deliveries.pickup
    }),
    maxCharacters() {
      const deviceWidth = document.documentElement.clientWidth
      if (deviceWidth > 375) {
        return 30
      } else if (deviceWidth <= 375 && deviceWidth !== 320) {
        return 18
      } else if (deviceWidth === 320) {
        return 12
      } else {
        return 20
      }
    }
  },
  created() {
    this.pickup && this.type === 'pickup' ? (this.selectedCustomer = this.pickup) : null
    this.dropOff && this.type === 'dropOff' ? (this.selectedCustomer = this.dropOff) : null
    this.billing && this.type === 'billing' ? (this.selectedCustomer = this.billing) : null
  },
  mounted() {
    this.$refs.searchField.focus()
  },
  methods: {
    ...mapMutations({
      selectPickup: 'deliveries/setPickup',
      selectBilling: 'deliveries/setBilling',
      selectDropoff: 'deliveries/setDropOff',
      resetSearchedCustomer: 'customers/resetSearchedCustomer'
    }),
    ...mapActions({
      searchCustomers: 'customers/search'
    }),
    search: _.debounce(async function(e) {
      await this.searchCustomers({'filters[businessName]': e.target.value})
    }, 500),
    select(cus) {
      this.customers.forEach(cus => (cus.active = false))
      cus.active = true
      this.selectedCustomer = cus
    },
    done() {
      if (!this.selectedCustomer) return
      if (this.type === 'pickup') this.selectPickup(this.selectedCustomer)
      else if (this.type === 'billing') this.selectBilling(this.selectedCustomer)
      else if (this.type === 'dropOff') this.selectDropoff({dropOff: this.selectedCustomer, index: this.index})
      this.$modal.hide('SearchPublicCustomerModal')
    }
  },
  beforeDestroy() {
    this.resetSearchedCustomer()
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
