<template>
  <div v-if="hasMultipleIds" class="sidebar__main">
    <h2>Select a delivery</h2>
    <ul class="list-unstyled">
      <li v-for="id in data.ids" :key="id" @click="selectDelivery(id)">Route #{{ id }}</li>
    </ul>
  </div>
  <div v-else-if="isLoaded" class="sidebar__main">
    <div class="sidebar__title">
      <span>Route #{{ id }}</span>
    </div>
    <div class="sidebar__main-inner">
      <h2>Status:</h2>
      <span v-if="delivery.status">Done</span>
      <span v-else>Not Done</span>
      <h2>Info:</h2>
      <ul class="list-unstyled">
        <li>Route Date - {{ new Date(delivery.routeDate).toISOString().split('T')[0] }}</li>
        <li>Billing - {{ delivery.billing }}</li>
        <li>Service - {{ delivery.serviceName }} (ServiceID: {{ delivery.serviceID }})</li>
      </ul>
      <h2>Customers:</h2>
      <ul v-for="customer in delivery.customers" :key="customer.id" class="list-unstyled">
        ---------------------------------------------------
        <li>
          Status - {{ customer.status ? 'Done' : 'Not Done' }}
          <button v-if="!customer.status" @click="markDone(customer.id)">Done!</button>
        </li>
        <li>name - {{ customer.customerName }}</li>
        <li>type - {{ customer.type }}</li>
        <li>
          address -
          {{ [customer.address, customer.city, customer.province, customer.postalCode].filter(v => !!v).join(', ') }}
        </li>
        <li>notes - {{ customer.notes }}</li>
      </ul>
    </div>

    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div class="button" @click="closeSidebar">Close</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      ids: [
        {
          type: Number,
          required: true
        }
      ]
    }
  },
  data() {
    return {
      id: null,
      isLoaded: false
    }
  },
  name: 'MapDelivery',
  async mounted() {
    await this.loadDelivery()
  },
  beforeDestroy() {
    this.$root.$emit('reloadMarkers')
  },
  computed: {
    delivery() {
      return this.$store.getters['map/delivery']
    },
    hasMultipleIds() {
      return this.data.ids.length > 1
    },
    customers() {
      return this.delivery.customers.map(customer => {
        return {
          id: customer.id,
          deliveryID: this.delivery.id,
          type: customer.type,
          status: customer.status,
          customerName: customer.customerName,
          lat: customer.lat,
          lng: customer.lng
        }
      })
    }
  },
  methods: {
    async loadDelivery() {
      if (!this.hasMultipleIds) {
        this.id = this.data.ids[0]
        try {
          await this.$store.dispatch('map/fetchDelivery', this.id)
          this.$root.$emit('reloadMarkers', this.customers)
        } catch (err) {
          console.error(err)
          this.$root.$emit('ShowSidebar', null)
        } finally {
          this.isLoaded = true
        }
      }
    },
    selectDelivery(id) {
      this.data.ids = [id]
      this.loadDelivery()
    },
    async markDone(deliverableID) {
      await this.$store.dispatch('map/deliverableDone', deliverableID)
    },
    closeSidebar() {
      this.$root.$emit('ShowSidebar', null)
    }
  }
}
</script>
