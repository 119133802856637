<template>
  <div class="navbar__wrapper">
    <div class="container">
      <div class="navbar__inner">
        <div class="navbar__left">
          <div class="navbar__title">
            STS App
          </div>
        </div>
        <div>
          <nav class="navbar__navigation">
            <ul class="nav__list">
              <router-link class="nav__item" to="/map" active-class="nav__item--active">
                <i class="ri-map-pin-fill"></i>
                <span>Map</span>
              </router-link>
              <router-link class="nav__item" to="/deliveries" exact-active-class="nav__item--active">
                <i class="ri-pin-distance-fill"></i>
                <span>Deliveries</span>
              </router-link>
              <router-link v-if="role !== 'driver'" class="nav__item" to="/users" active-class="nav__item--active">
                <i class="ri-user-3-line"></i>
                <span>Users</span>
              </router-link>
              <router-link v-if="role !== 'driver'" class="nav__item" to="/services" active-class="nav__item--active">
                <i class="ri-stack-fill"></i>
                <span>Services</span>
              </router-link>
              <router-link v-if="role !== 'driver'" class="nav__item" to="/shifts" active-class="nav__item--active">
                <i class="ri-focus-line"></i>
                <span>Shifts</span>
              </router-link>
              <router-link v-if="role !== 'driver'" class="nav__item" to="/dashboard" active-class="nav__item--active">
                <i class="ri-donut-chart-line"></i>
                <span>Dashboard</span>
              </router-link>
              <router-link class="nav__item" to="/orders" active-class="nav__item--active">
                <i class="ri-map-pin-fill"></i>
                <span>Orders</span>
              </router-link>
            </ul>
          </nav>
        </div>
        <div class="navbar__right">
          <div v-if="hasBlock && hasBlock.isActive" class="navbar__ostatus" @click="changeBypassed">
            <i v-if="lockBtn" class="ri-lock-unlock-line"></i>
            <i v-else class="ri-lock-line"></i>
          </div>
          <div class="navbar__notification">
            <el-dropdown trigger="click" placement="bottom-end" @command="openNotification">
              <div class="navbar__notification-button">
                <i class="ri-notification-2-fill"></i>
                <div v-if="notificatonCounter" class="navbar__notification-number">{{ notificatonCounter }}</div>
              </div>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--notifications">
                <el-dropdown-item v-if="!notifications.length" class="item__notification--missing">
                  No notifications 😴
                </el-dropdown-item>
                <el-dropdown-item
                  v-for="not of notifications"
                  :key="not.id"
                  class="item__notification"
                  :class="{'item__notification--active': !not.wasRead}"
                  :command="not"
                >
                  <div
                    class="dot"
                    :class="{
                      'dot--green': not.status === 'PENDING' || !not.wasRead,
                      'dot--red': not.status === 'CANCELED',
                      'dot--yellow': not.wasRead
                    }"
                  ></div>
                  <div v-if="not.status === 'PENDING'">
                    New order {{ not.deliveryOrderID }} has been created by
                    <span class="item__notification-customer"> {{ not.author.name }}</span>
                  </div>
                  <div v-if="not.status === 'CANCELED'">Order {{ not.deliveryOrderID }} has been canceled</div>
                  <span class="item__notification-date">{{ moment(not.date).format('D MMM h:mm a') }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="letter navbar__letter">{{ nameFirstLetter }}</div>
          <div class="navbar__name">{{ $store.getters.user.name }}</div>
          <div class="navbar__actions">
            <el-dropdown trigger="click" @command="action">
              <i class="ri-more-fill"></i>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                <el-dropdown-item class="item--edit" command="export">
                  <i class="ri-file-chart-fill"></i> Export
                </el-dropdown-item>
                <el-dropdown-item class="item--edit" command="surcharge">
                  <i class="ri-survey-fill"></i> Surcharge
                </el-dropdown-item>
                <el-dropdown-item v-if="user.id === adminID" class="item--edit" command="invoices">
                  <i class="ri-money-dollar-circle-fill"></i> Finance
                </el-dropdown-item>
                <el-dropdown-item v-if="user.id === adminID" class="item--edit" command="operation">
                  <i class="ri-lock-2-fill"></i> Operation Status
                </el-dropdown-item>
                <el-dropdown-item class="item--edit" command="Logout">
                  <i class="ri-logout-circle-line"></i> Logout
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="navbar__mobile">
        <el-dropdown trigger="click" placement="bottom-start" @visible-change="showDropBg">
          <button class="icon-button">
            <i class="ri-menu-line"></i>
          </button>
          <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper-custom--nav">
            <el-dropdown-item>
              <router-link to="/map" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-map-pin-fill"></i>
                <span>Map</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link to="/deliveries" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-pin-distance-fill"></i>
                <span>Deliveries</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item v-if="role !== 'driver'">
              <router-link to="/users" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-user-3-line"></i>
                <span>Users</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item v-if="role !== 'driver'">
              <router-link to="/services" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-stack-fill"></i>
                <span>Services</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item v-if="role !== 'driver'">
              <router-link to="/shifts" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-focus-line"></i>
                <span>Shifts</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item v-if="role !== 'driver'">
              <router-link to="/dashboard" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-donut-chart-line"></i>
                <span>Dashboard</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link to="/orders" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-map-pin-fill"></i>
                <span>Orders</span>
              </router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <div v-if="$route.meta.parent === 'Users'" class="navbar__title">Users</div>
        <div v-else-if="$route.name === 'AdminOrderPage'" class="navbar__title">Order / #{{ $route.params.id }}</div>
        <div v-else-if="$route.name === 'ExportDeliveries'" class="navbar__title">Export Deliveries</div>
        <div v-else class="navbar__title">{{ path.name }}</div>
        <div v-if="$route.meta.parent === 'Users'">
          <Multiselect
            v-model="link"
            :options="links"
            class="select-users"
            track-by="key"
            label="title"
            :show-labels="false"
            :allow-empty="false"
            :searchable="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="dropdown-link">
                <i :class="props.option.icon"></i>
                <span>{{ props.option.title }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <router-link :to="props.option.path + '/'" class="dropdown-link" active-class="dropdown-link--active">
                <i :class="props.option.icon"></i>
                <span>{{ props.option.title }}</span>
              </router-link>
            </template>
          </Multiselect>
        </div>
        <div v-else></div>
        <div v-if="hasBlock" class="navbar__ostatus" @click="changeBypassed">
          <i v-if="lockBtn" class="ri-lock-unlock-line"></i>
          <i v-else class="ri-lock-line"></i>
        </div>
        <div class="navbar__notification">
          <el-dropdown trigger="click" placement="bottom-end" @command="openNotification">
            <div class="navbar__notification-button">
              <i class="ri-notification-2-fill"></i>
              <div v-if="notificatonCounter" class="navbar__notification-number">{{ notificatonCounter }}</div>
            </div>
            <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--notifications">
              <el-dropdown-item v-if="!notifications.length" class="item__notification--missing">
                No notifications 😴
              </el-dropdown-item>
              <el-dropdown-item
                v-for="not of notifications"
                :key="not.id"
                class="item__notification"
                :class="{'item__notification--active': !not.wasRead}"
                :command="not"
              >
                <div
                  class="dot"
                  :class="{
                    'dot--green': not.status === 'PENDING' || !not.wasRead,
                    'dot--red': not.status === 'CANCELED',
                    'dot--yellow': not.wasRead
                  }"
                ></div>
                <div v-if="not.status === 'PENDING'">
                  New order {{ not.deliveryOrderID }} has been created by
                  <span class="item__notification-customer"> {{ not.author.name }}</span>
                </div>
                <div v-if="not.status === 'CANCELED'">Order {{ not.deliveryOrderID }} has been canceled</div>
                <span class="item__notification-date">{{ moment.utc(not.date).format('D MMM h:mm a') }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-dropdown trigger="click" class="end" @command="action">
          <button class="icon-button icon-button--round">
            <i class="ri-more-fill"></i>
          </button>
          <el-dropdown-menu slot="dropdown" class="el-popper-custom">
            <el-dropdown-item class="item--edit" command="export">
              <i class="ri-file-chart-fill"></i> Export
            </el-dropdown-item>
            <el-dropdown-item class="item--edit" command="surcharge">
              <i class="ri-survey-fill"></i> Surcharge
            </el-dropdown-item>
            <el-dropdown-item v-if="user.id === adminID" class="item--edit" command="invoices">
              <i class="ri-money-dollar-circle-fill"></i> Finance
            </el-dropdown-item>
            <el-dropdown-item v-if="user.id === adminID" class="item--edit" command="operation">
              <i class="ri-lock-2-fill"></i> Operation Status
            </el-dropdown-item>
            <el-dropdown-item class="item--edit" command="Logout">
              <i class="ri-logout-circle-line"></i> Logout
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-if="showBg" class="mobile-bg"></div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {mapState, mapGetters, mapActions} from 'vuex'
import Pusher from 'pusher-js'
import MD5 from 'crypto-js/md5'
import moment from 'moment'
import SurchargeModal from '../FuelSurchargeModal'

export default {
  name: 'NavbarAdmin',
  components: {Multiselect},
  data: () => ({
    moment,
    name: '',
    letter: '',
    role: '',
    link: {key: 1, title: 'Drivers', path: '/users/drivers', icon: 'ri-steering-2-fill'},
    links: [
      {key: 1, title: 'Drivers', path: '/users/drivers', icon: 'ri-steering-2-fill'},
      {key: 2, title: 'Customers', path: '/users/customers', icon: 'ri-user-3-fill'},
      {key: 4, title: 'Public', path: '/users/clients', icon: 'ri-user-2-fill'}
    ],
    showBg: false,
    pusher: null,
    adminID: +process.env.VUE_APP_ADMIN_ID
  }),
  computed: {
    path() {
      return this.$route
    },
    ...mapState({
      notifications: s => s.orderNotifications.notifications,
      hasBlock: s => s.operationStatus.hasBlock
    }),
    ...mapGetters({
      user: 'decryptToken'
    }),
    lockBtn() {
      if (this.hasBlock?.bypassed) {
        return true
      } else {
        return false
      }
    },
    notificatonCounter() {
      const counter = this.notifications.filter(n => !n.wasRead).length
      if (counter > 99) return '99+'
      return counter
    },
    nameFirstLetter() {
      return this.$store.getters.user.name ? this.$store.getters.user.name[0] : ''
    }
  },
  async created() {
    if (this.$store.getters.token) {
      this.role = JSON.parse(atob(this.$store.getters.token.split('.')[1])).role
    }
    if (this.user.id === this.adminID) {
      this.links.splice(2, 0, {key: 3, title: 'Enterprise', path: '/users/enterprise', icon: 'ri-building-2-fill'})
    }
    switch (this.$route.name) {
      case 'Drivers':
        this.link = {key: 1, title: 'Drivers', path: '/users/drivers', icon: 'ri-steering-2-fill'}
        break
      case 'Customers':
        this.link = {key: 2, title: 'Customers', path: '/users/customers', icon: 'ri-user-3-fill'}
        break
      case 'EnterpriseCustomers':
        this.link = {key: 3, title: 'Customers', path: '/users/enterprise', icon: 'ri-building-2-fill"'}
        break
      case 'PublicCustomers':
        this.link = {key: 4, title: 'Public', path: '/users/clients', icon: 'ri-user-2-fill'}
        break
    }
    this.subscribe()
    await this.$store.dispatch('orderNotifications/getNotifications')
    await this.getBlockInfo()
  },
  beforeDestroy() {
    const userHash = MD5(this.$store.getters.user.id.toString()).toString()
    this.pusher.unsubscribe(userHash)
  },
  methods: {
    ...mapActions({
      getBlockInfo: 'operationStatus/getBlockInfo',
      byPassBlock: 'operationStatus/byPassBlock',
      byPassEnd: 'operationStatus/byPassEnd'
    }),
    async changeBypassed() {
      if (this.hasBlock?.bypassed) await this.byPassEnd()
      else await this.byPassBlock()
    },
    action(act) {
      if (act === 'Logout') {
        this.$store.dispatch('logout')
        this.$router.go('/login')
      } else if (act === 'surcharge') {
        this.$modal.display(
          SurchargeModal,
          {},
          {
            name: 'FuelSurchargeModal',
            transition: 'none',
            overlayTransition: 'none',
            width: 300,
            height: 'auto',
            classes: 'modal modal-surcharge'
          }
        )
      } else if (act === 'export') {
        this.$router.push('/deliveries/export/')
      } else if (act === 'invoices') {
        this.$router.push('/admin/invoices')
      } else if (act === 'operation') {
        this.$root.$emit('ShowSidebar', {
          sidebarName: 'OperationStatus'
        })
      }
    },
    showDropBg() {
      this.showBg = !this.showBg
    },
    subscribe() {
      const pusherID = process.env.VUE_APP_PUSHER
      const pusher = (this.pusher = new Pusher(pusherID, {cluster: 'us2'}))
      const userHash = MD5(this.$store.getters.user.id.toString()).toString()
      pusher.subscribe(userHash)
      pusher.bind('delivery-order.created', data => {
        this.$store.commit('orderNotifications/setCreatedNotification', data)
      })
      pusher.bind('delivery-order.canceled', data => {
        this.$store.commit('orderNotifications/setCanceledNotification', data)
      })
    },
    async openNotification(notification) {
      await this.$store.dispatch('orderNotifications/readNotification', notification.id)
      this.$router.push('/orders/' + notification.deliveryOrderID)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
