import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    invoices: [],
    invoice: {},
    pagination: {},
    page: 1
  },
  mutations: {
    set(state, invoices) {
      state.invoices = _.unionBy(state.invoices, invoices, 'id')
    },
    setInvoice(state, invoice) {
      state.invoice = invoice
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.invoice = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    },
    remove(state, id) {
      // state.invoices.splice(state.invoices.map(invoice => invoice.id).indexOf(id), 1)
      state.invoices = state.invoices.filter(inv => inv.id !== id)
    }
  },
  actions: {
    async fetch({commit, state}) {
      const page = state.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const invoices = await axios.get(url + '/invoices/', {
          params: {page}
        })
        commit('set', invoices.data.invoices)
        commit('pagination', invoices.data.pagination)
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchInvoice({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const invoice = await axios.get(url + '/invoices/' + id)
        commit('setInvoice', invoice.data.invoice)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async confirm({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.patch(url + `/invoices/${id}/mark-as-paid`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async generate({commit, dispatch}) {
      try {
        commit('reset')
        const url = process.env.VUE_APP_BACKEND
        await axios.post(`${url}/invoices/generate`)
        await dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
  }
}
