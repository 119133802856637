<template>
  <div class="navbar__wrapper">
    <div class="container">
      <div class="navbar__inner">
        <div class="navbar__left">
          <div class="navbar__title">
            STS App
          </div>
        </div>
        <div class="navbar__center">
          <nav class="navbar__navigation">
            <ul class="nav__list">
              <router-link class="nav__item" to="/companies" active-class="nav__item--active">
                <i class="ri-truck-fill"></i>
                <span>Companies</span>
              </router-link>
              <router-link class="nav__item" to="/clients" active-class="nav__item--active">
                <i class="ri-user-2-fill"></i>
                <span>Public Clients</span>
              </router-link>
            </ul>
          </nav>
        </div>
        <div class="navbar__right">
          <div class="letter navbar__letter">{{ nameFirstLetter }}</div>
          <div class="navbar__name">{{ $store.getters.user.name }}</div>
          <div class="navbar__actions">
            <el-dropdown trigger="click" @command="action">
              <i class="ri-more-fill"></i>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                <el-dropdown-item class="item--edit" command="Logout">
                  <i class="ri-logout-circle-line"></i> Logout
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="navbar__mobile">
        <el-dropdown trigger="click" placement="bottom-start" @visible-change="showDropBg">
          <button class="icon-button">
            <i class="ri-menu-line"></i>
          </button>
          <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper-custom--nav">
            <el-dropdown-item>
              <router-link to="/companies" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-truck-fill"></i>
                <span>Companies</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link to="/clients" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-user-2-fill"></i>
                <span>Public Clients</span>
              </router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="navbar__title">{{ path.name }}</div>
        <el-dropdown class="end" trigger="click" @command="action">
          <button class="icon-button icon-button--round">
            <i class="ri-more-fill"></i>
          </button>
          <el-dropdown-menu slot="dropdown" class="el-popper-custom">
            <el-dropdown-item class="item--edit" command="Logout">
              <i class="ri-logout-circle-line"></i> Logout
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-if="showBg" class="mobile-bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavbarSuperAdmin',
  data: () => ({
    showBg: false
  }),
  computed: {
    path() {
      return this.$route
    },
    nameFirstLetter() {
      return this.$store.getters.user.name ? this.$store.getters.user.name[0] : ''
    }
  },
  async created() {
    if (!this.$store.getters['companies/pagination'].total) {
      this.$store.dispatch('companies/fetch')
    }
  },
  methods: {
    action(act) {
      if (act === 'Logout') {
        this.$store.dispatch('logout')
        this.$router.go('/login')
      }
    },
    showDropBg() {
      this.showBg = !this.showBg
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
