<template>
  <div class="sidebar__main">
    <div class="sidebar__title">
      <span v-if="data">Edit - {{ data.name }}</span>
      <span v-else>Add New Service</span>
    </div>
    <div class="sidebar__main-inner">
      <div class="sidebar__row">
        <div class="sidebar__row-title">Basic Data</div>
        <div class="sidebar__row-main">
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.name,
              'field__label--invalid': $v.sendData.name.$dirty && !$v.sendData.name.required
            }"
          >
            <div class="field__title">Service Name</div>
            <input v-model="$v.sendData.name.$model" class="field__input" type="text" placeholder="Service Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.price,
              'field__label--invalid':
                ($v.sendData.price.$dirty && !$v.sendData.price.required) ||
                ($v.sendData.price.$dirty && !$v.sendData.price.numeric)
            }"
          >
            <div class="field__title">Price</div>
            <input v-model="$v.sendData.price.$model" class="field__input" type="tel" placeholder="Price" />
          </label>
        </div>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Availability for Shifts</div>
        <div class="sidebar__row-shifts">
          <div v-for="shift in shifts" :key="shift.id" class="sidebar__row-shift">
            <div class="shift__round" :style="{backgroundColor: shift.color}"></div>
            <div class="shift__title">{{ shift.name }}</div>
            <div class="shift__switch">
              <toggle-button v-model="shift.active" color="#0290FC" :width="40" />
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Service Color</div>
        <v-swatches v-model="sendData.color" inline></v-swatches>
      </div>
      <div class="sidebar__row">
        <div class="field__label--swich">
          <div class="shift__title">Fuel Surcharge</div>
          <el-switch v-model="sendData.fuelSuperCharge" class="swich" />
        </div>
      </div>
      <div class="sidebar__row">
        <div class="field__label--swich">
          <div class="shift__title">Public</div>
          <el-switch v-model="sendData.isPublic" :disabled="!!sendData.customers.length" class="swich" />
        </div>
      </div>
      <div v-if="user.id === adminID" class="sidebar__row">
        <div class="sidebar__row-title">Enterprise</div>
        <div class="sidebar__row-dropdown">
          <div class="sidebar__row-dropdown-icon">
            <div class="icon-button icon-button--round icon-button--blue">
              <i class="ri-user-3-line"></i>
            </div>
          </div>
          <Multiselect
            v-model="sendData.customers"
            :options="searchCus"
            placeholder="Select customers"
            class="select-custom"
            track-by="id"
            label="businessName"
            :multiple="true"
            :show-labels="false"
            :disabled="sendData.isPublic"
          >
            <template slot="selection" slot-scope="{values, isOpen}">
              <span v-if="values.length && !isOpen" class="multiselect__single">
                {{ values.length }} options selected
              </span>
            </template>
            <template slot="tag">{{ '' }}</template>
            <template slot="option" slot-scope="{option}">
              <div class="option__item">
                <div class="option__item-icon">
                  <i class="ri-checkbox-circle-fill"></i>
                </div>
                <div class="option__item-customer">
                  <div class="option__item-name">
                    <span>{{ option.businessName }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template slot="noOptions">
              <div class="label__item--options">
                <span class="label__title">Please type business name</span>
              </div>
            </template>
            <template slot="noResult">
              <div class="label__item--options">
                <span class="label__title">No business name like that, please try again</span>
              </div>
            </template>
          </Multiselect>
          <div class="sidebar__customers-list">
            <div v-for="cus of sendData.customers" :key="cus.id" class="sidebar__customers-item">
              <span>{{ cus.businessName }}</span>
              <button class="icon-button icon-button--round">
                <i class="ri-delete-bin-7-fill" @click="delCus(cus)"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="sidebar__row-icon">
          <div class="icon-wrap"><i :class="[icon]"></i></div>

          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.name,
              'field__label--invalid': $v.sendData.name.$dirty && !$v.sendData.name.required
            }"
          >
            <input v-model="sendData.icon" class="field__input" type="text" placeholder="icon-name" @input="cut" />
          </label>
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div class="button button--fill" @click="createService">
          <span v-if="data">Save Changes</span>
          <span v-else>Create Service</span>
        </div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'
import {required, numeric} from 'vuelidate/lib/validators'
import VSwatches from 'vue-swatches'
import Multiselect from 'vue-multiselect'
import {mapState, mapActions, mapGetters} from 'vuex'

export default {
  name: 'AddService',
  components: {ToggleButton, VSwatches, Multiselect},
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    sendData: {
      fuelSuperCharge: false,
      name: '',
      price: '',
      color: '#1FBC9C',
      icon: null,
      customers: [],
      isPublic: false
    },
    adminID: +process.env.VUE_APP_ADMIN_ID,
    icon: null
  }),
  computed: {
    ...mapState({
      searchCus: s => s.customers.enterprise.customers
    }),
    ...mapGetters({
      user: 'decryptToken'
    }),
    shifts() {
      return this.$store.getters['shifts/shifts'].map(shift => {
        const changedShift = {...shift}
        if (this.data?.shifts.find(sh => sh.id === shift.id)) {
          changedShift.active = true
        } else {
          changedShift.active = false
        }
        return changedShift
      })
    },
    activeShifts() {
      return this.shifts.filter(shift => shift.active).map(shift => shift.id)
    }
  },
  async created() {
    if (!this.$store.getters['shifts/shifts'].length) await this.$store.dispatch('shifts/fetch')
    if (!this.searchCus.length) await this.fetchCustomers()
    if (this.data) {
      this.sendData = Object.assign(this.sendData, this.data)
      this.cut()
    }
  },
  methods: {
    ...mapActions({
      fetchCustomers: 'customers/enterprise/fetch'
    }),
    cut() {
      const iconDom = this.sendData.icon
      if (!iconDom) return
      const start = iconDom.indexOf('="') + 2
      const end = iconDom.indexOf('">')
      this.icon = iconDom.slice(start, end)
    },
    async createService() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const {name, price, fuelSuperCharge, color, icon, isPublic} = this.sendData
      const sendData = {
        name,
        price,
        fuelSuperCharge,
        shifts: this.activeShifts,
        color,
        icon,
        isPublic
      }
      if (!this.sendData.isPublic) {
        sendData.customers = this.sendData.customers.map(c => c.id)
      }
      try {
        if (this.data) {
          sendData.id = this.data.id
          await this.$store.dispatch('services/update', sendData)
        } else {
          await this.$store.dispatch('services/create', sendData)
        }
        this.$root.$emit('ShowSidebar', null)
      } catch (e) {
        if (e.response.data.error) {
          alert(e.response.data.error)
        } else {
          alert(e.response.data.errors[0].error)
        }
      }
    },
    delCus(cus) {
      this.sendData.customers = this.sendData.customers.filter(c => c.id !== cus.id)
    }
  },
  validations: {
    sendData: {
      name: {required},
      price: {required, numeric},
      fuelSuperCharge: {required},
      isPublic: {required}
    },
    activeShifts: {required}
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
