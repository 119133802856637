import axios from 'axios'
import customers from './publicCustomers'

export default {
  namespaced: true,

  state: {
    services: [],
    service: {},
    cost: null
  },
  mutations: {
    setServices(state, services) {
      state.services = services
    },
    setService(state, service) {
      state.service = service
    },
    setCost(state, cost) {
      state.cost = cost
    }
  },
  actions: {
    async fetchServices({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const services = await axios.get(url + `/services/${process.env.VUE_APP_COMPANY_ID}/list`)
        commit('setServices', services.data.result)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async pay({commit}, {id, pmID}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.put(url + `/delivery-orders/${id}/payment`, {paymentMethodID: pmID})
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async getCost({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        let serviseID = null
        if (id) {
          serviseID = id
        } else {
          serviseID = process.env.VUE_APP_SERVICE_ID
        }
        const cost = await axios.get(url + `/delivery-orders/service/${serviseID}/cost`)
        commit('setCost', cost.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  },
  getters: {
    services: s => s.services,
    price: s => s.services[0]?.price,
    serviceID: s => s.service?.id
  },
  modules: {
    customers
  }
}
