<template>
  <div class="sidebar__main">
    <div class="sidebar__title">
      <span>Operation Status</span>
      <ToggleButton v-model="active" colour="#0290FC" :width="40" />
    </div>
    <div class="sidebar__main-inner">
      <div class="sidebar__row">
        <div class="sidebar__row-main">
          <div class="sidebar__row-title">Add comment</div>
          <textarea
            v-model="sendData.message"
            class="field__text"
            placeholder="Start typing here..."
            :disabled="!active"
          ></textarea>
          <div class="sidebar__row-title">Select colour</div>
          <v-swatches
            v-model="sendData.colour"
            inline
            :disabled="!active"
            :swatches="['#DA4040', '#F6D903', '#7B61FF', '#FF5C02']"
          ></v-swatches>
          <div class="sidebar__row-title">Select Date</div>
          <date-picker
            v-model="sendData.dateRange"
            format="YYYY-MM-DD"
            value-type="format"
            :editable="false"
            placeholder="Select date"
            range
            class="new-order-date"
            :disabled="!active"
          >
            <template slot="icon-calendar">
              <i class="ri-calendar-fill"></i>
            </template>
          </date-picker>
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div class="button button--fill" @click="createBlock">
          <span>Save Changes</span>
        </div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'
import VSwatches from 'vue-swatches'
import DatePicker from 'vue2-datepicker'
import {requiredIf} from 'vuelidate/lib/validators'
import moment from 'moment'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'OperationService',
  components: {ToggleButton, DatePicker, VSwatches},
  data: () => ({
    isLoading: false,
    active: false,
    isUpdate: false,
    sendData: {
      dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      message: null,
      colour: null
    }
  }),
  computed: {
    ...mapState({
      hasBlock: s => s.operationStatus.hasBlock
    })
  },
  created() {
    if (this.hasBlock) {
      this.isUpdate = true
      this.sendData.dateRange = [
        moment(this.hasBlock.from)
          .utc()
          .format('YYYY-MM-DD'),
        moment(this.hasBlock.till)
          .utc()
          .format('YYYY-MM-DD')
      ]
      this.sendData.message = this.hasBlock.message
      this.sendData.colour = this.hasBlock.colour
      this.active = true
    }
  },
  methods: {
    ...mapActions({
      create: 'operationStatus/createBlock',
      update: 'operationStatus/updateBlock',
      clear: 'operationStatus/clearBlocks'
    }),
    async createBlock() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const sendData = {
        from: this.sendData.dateRange[0],
        till: this.sendData.dateRange[1],
        message: this.sendData.message,
        colour: this.sendData.colour
      }
      try {
        this.isLoading = true
        if (this.active && this.isUpdate) {
          this.update(sendData)
        } else if (this.active) {
          this.create(sendData)
        } else {
          this.clear()
        }
        this.$root.$emit('ShowSidebar', null)
      } finally {
        this.isLoading = false
      }
    }
  },
  validations: {
    sendData: {
      dateRange: {
        required: requiredIf(function() {
          if (this.active) return true
        })
      },
      colour: {
        required: requiredIf(function() {
          if (this.active) return true
        })
      },
      message: {
        required: requiredIf(function() {
          if (this.active) return true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
