import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    clients: [],
    client: {},
    pagination: {},
    page: 1,
    // FILTER PARAMS
    searchParam: null,
    filterParamsSend: {}
  },
  mutations: {
    set(state, clients) {
      state.clients = _.unionBy(state.clients, clients, 'id')
    },
    setClient(state, client) {
      state.client = client
    },
    changeSearch(state, param) {
      state.searchParam = param
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.clients = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    },
    // FILTER PARAMS
    filterParamsSend(state, params) {
      state.filterParamsSend = params
    }
  },
  actions: {
    async fetch({commit, state}, params) {
      const page = state.page
      const searchParam = {'filters[businessName]': state.searchParam}
      try {
        const url = process.env.VUE_APP_BACKEND
        const clients = await axios.get(url + '/admin/customers', {
          params: {...params, ...searchParam, ...state.filterParamsSend, page, perPage: 24}
        })
        commit('set', clients.data.result[0])
        commit('pagination', clients.data.result[1])
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async create({commit}, client) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.post(url + '/admin/customers', client)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async update({commit}, client) {
      const id = client.id
      delete client.id
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.put(url + '/admin/customers/' + id, client)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async delete({commit, dispatch}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.delete(url + '/admin/customers/' + id)
        commit('reset')
        await dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async uploadPhoto({commit}, {file, id}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const formData = new FormData()
        formData.append('attachment', file)
        return await axios.post(url + `/admin/customers/${id}/attachment`, formData)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async removePhoto({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.delete(url + `/admin/customers/${id}/attachment`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    clients: s => s.clients,
    client: s => s.client,
    searchParam: s => s.searchParam,
    pagination: s => s.pagination,
    totalCount: s => s.pagination.total,
    page: s => s.page
  }
}
