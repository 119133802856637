import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    drivers: [],
    driver: {},
    pagination: {},
    page: 1
  },
  mutations: {
    set(state, drivers) {
      state.drivers = _.unionBy(state.drivers, drivers, 'id')
    },
    change(state, item) {
      if (typeof item === 'object') {
        state.drivers = state.drivers.map(driver => {
          if (driver.id === item.id) {
            return Object.assign(driver, item)
          } else {
            return driver
          }
        })
      } else if (typeof item === 'number') {
        const position = state.drivers.findIndex(sh => sh.id === item)
        state.drivers.splice(position, 1)
        state.pagination.total--
      }
    },
    setDriver(state, driver) {
      state.driver = driver
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.drivers = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    }
  },
  actions: {
    async fetch({commit, getters}) {
      let page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const drivers = await axios.get(url + '/drivers', {
          params: {page}
        })
        commit('pagination', drivers.data.result[1])
        commit('set', drivers.data.result[0])
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async create({commit, dispatch}, driver) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.post(url + '/drivers', driver)
        commit('changePage', 1)
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async update({commit}, driver) {
      const id = driver.id
      delete driver.id
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + '/drivers/' + id, driver)
        driver.id = id
        commit('change', driver)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async activate({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/drivers/' + id + '/activate')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async deactivate({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/drivers/' + id + '/deactivate')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async delete({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.delete(url + '/drivers/' + id)
        // commit('reset')
        // dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    // async delActions({commit, dispatch}, id) {
    //   try {
    //     const url = process.env.VUE_APP_BACKEND
    //     await axios.delete(url + '/actions/' + id)
    //     commit('resetActions')
    //     dispatch('fetchActions')
    //   } catch (e) {
    //     commit('setError', e)
    //   }
    // },
    // async editAction({commit, dispatch}, {id, data}) {
    //   try {
    //     const url = process.env.VUE_APP_BACKEND
    //     await axios.put(url + '/actions/' + id, data)
    //     commit('resetActions')
    //     await dispatch('fetchActions')
    //   } catch (e) {
    //     commit('setError', e)
    //     throw e
    //   }
    // }
  },
  getters: {
    drivers: s => s.drivers,
    driver: s => s.driver,
    pagination: s => s.pagination,
    page: s => s.page
  }
}
