import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    // orders: [],
  },
  mutations: {
    // set(state, orders) {
    //   state.orders = _.unionBy(state.orders, orders, 'id')
    // },
  },
  actions: {
    async invite({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.patch(url + `/admin/customers/${id}/invite`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async check({commit}, token) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const reqCheck = await axios.get(url + `/customers/invites/${token}`)
        return reqCheck.data.success
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async register({commit}, {token, pass}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const reqRegister = await axios.post(url + `/customers/invites/${token}`, pass)
        return reqRegister.data.result
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    // page: s => s.page
  }
}
