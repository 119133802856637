var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar__main"},[_c('div',{staticClass:"sidebar__title"},[(_vm.data)?_c('span',[_vm._v("Edit - "+_vm._s(_vm.data.name))]):_c('span',[_vm._v("Add New Driver")])]),_c('div',{staticClass:"sidebar__main-inner"},[_c('div',{staticClass:"sidebar__row"},[_c('div',{staticClass:"sidebar__row-title"},[_vm._v("Basic Data")]),_c('div',{staticClass:"sidebar__row-main sidebar__row-main--half"},[_c('label',{staticClass:"field__label",class:{
            'field__label--filled': _vm.$v.sendData.name.$model && _vm.$v.sendData.name.$dirty,
            'field__label--invalid': _vm.$v.sendData.name.$dirty && !_vm.$v.sendData.name.required
          }},[_c('div',{staticClass:"field__title"},[_vm._v("Driver Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.sendData.name.$model),expression:"$v.sendData.name.$model"}],staticClass:"field__input",attrs:{"type":"text","placeholder":"Driver Name"},domProps:{"value":(_vm.$v.sendData.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.sendData.name, "$model", $event.target.value)}}})]),_c('label',{staticClass:"field__label",class:{
            'field__label--filled': _vm.$v.sendData.username.$model && _vm.$v.sendData.username.$dirty,
            'field__label--invalid': _vm.$v.sendData.username.$dirty && !_vm.$v.sendData.username.required
          }},[_c('div',{staticClass:"field__title"},[_vm._v("User Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.sendData.username.$model),expression:"$v.sendData.username.$model"}],staticClass:"field__input",attrs:{"type":"text","placeholder":"User Name"},domProps:{"value":(_vm.$v.sendData.username.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.sendData.username, "$model", $event.target.value)}}})]),_c('label',{staticClass:"field__label",class:{
            'field__label--filled':
              _vm.$v.sendData.phone.$model && _vm.$v.sendData.phone.$dirty && _vm.$v.sendData.phone.phoneReg,
            'field__label--invalid':
              (_vm.$v.sendData.phone.$dirty && !_vm.$v.sendData.phone.required) ||
              (_vm.$v.sendData.phone.$dirty && !_vm.$v.sendData.phone.phoneReg)
          }},[_c('div',{staticClass:"field__title"},[_vm._v("Phone Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.sendData.phone.$model),expression:"$v.sendData.phone.$model"},{name:"mask",rawName:"v-mask",value:('### ### ####'),expression:"'### ### ####'"}],staticClass:"field__input",attrs:{"type":"text","placeholder":"Phone Number"},domProps:{"value":(_vm.$v.sendData.phone.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.sendData.phone, "$model", $event.target.value)}}})]),_c('label',{staticClass:"field__label",class:{
            'field__label--filled': _vm.$v.sendData.email.$model && _vm.$v.sendData.email.email && _vm.$v.sendData.email.$dirty,
            'field__label--invalid':
              (_vm.$v.sendData.email.$dirty && !_vm.$v.sendData.email.required) ||
              (_vm.$v.sendData.email.$dirty && !_vm.$v.sendData.email.email)
          }},[_c('div',{staticClass:"field__title"},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.sendData.email.$model),expression:"$v.sendData.email.$model"}],staticClass:"field__input",attrs:{"type":"text","placeholder":"E-mail"},domProps:{"value":(_vm.$v.sendData.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.sendData.email, "$model", $event.target.value)}}})]),_c('label',{staticClass:"field__label",class:{
            'field__label--filled':
              _vm.$v.sendData.password.$model && _vm.$v.sendData.password.$dirty && _vm.$v.sendData.password.minLength,
            'field__label--invalid':
              (_vm.$v.sendData.password.$dirty && !_vm.$v.sendData.password.required) ||
              (_vm.$v.sendData.password.$dirty && !_vm.$v.sendData.password.minLength)
          }},[_c('div',{staticClass:"field__title"},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.sendData.password.$model),expression:"$v.sendData.password.$model"}],staticClass:"field__input",attrs:{"type":"text","placeholder":"Password"},domProps:{"value":(_vm.$v.sendData.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.sendData.password, "$model", $event.target.value)}}})])])]),_c('div',{staticClass:"sidebar__row sidebar__row--last"},[_c('div',{staticClass:"sidebar__row-title"},[_vm._v("Availability for Shifts")]),_c('div',{staticClass:"sidebar__row-shifts"},_vm._l((_vm.shifts),function(shift){return _c('div',{key:shift.id,staticClass:"sidebar__row-shift"},[_c('div',{staticClass:"shift__round",style:({backgroundColor: shift.color})}),_c('div',{staticClass:"shift__title"},[_vm._v(_vm._s(shift.name))]),_c('div',{staticClass:"shift__switch"},[_c('ToggleButton',{attrs:{"color":"#0290FC","width":40},model:{value:(shift.active),callback:function ($$v) {_vm.$set(shift, "active", $$v)},expression:"shift.active"}})],1)])}),0)])]),_c('div',{staticClass:"sidebar__footer"},[_c('div',{staticClass:"sidebar__footer-inner"},[_c('div',{staticClass:"button button--fill",on:{"click":_vm.createDriver}},[(_vm.data)?_c('span',[_vm._v("Save Changes")]):_c('span',[_vm._v("Create Driver")])]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.$root.$emit('ShowSidebar', null)}}},[_vm._v("Cancel")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }