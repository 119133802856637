<template>
  <div class="sidebar__main">
    <div class="sidebar__title">
      <span v-if="data">Edit - {{ data.name }}</span>
      <span v-else>Add New Driver</span>
    </div>
    <div class="sidebar__main-inner">
      <div class="sidebar__row">
        <div class="sidebar__row-title">Basic Data</div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.name.$model && $v.sendData.name.$dirty,
              'field__label--invalid': $v.sendData.name.$dirty && !$v.sendData.name.required
            }"
          >
            <div class="field__title">Driver Name</div>
            <input v-model="$v.sendData.name.$model" class="field__input" type="text" placeholder="Driver Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.username.$model && $v.sendData.username.$dirty,
              'field__label--invalid': $v.sendData.username.$dirty && !$v.sendData.username.required
            }"
          >
            <div class="field__title">User Name</div>
            <input v-model="$v.sendData.username.$model" class="field__input" type="text" placeholder="User Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.phone.$model && $v.sendData.phone.$dirty && $v.sendData.phone.phoneReg,
              'field__label--invalid':
                ($v.sendData.phone.$dirty && !$v.sendData.phone.required) ||
                ($v.sendData.phone.$dirty && !$v.sendData.phone.phoneReg)
            }"
          >
            <div class="field__title">Phone Number</div>
            <input
              v-model="$v.sendData.phone.$model"
              v-mask="'### ### ####'"
              class="field__input"
              type="text"
              placeholder="Phone Number"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.email.$model && $v.sendData.email.email && $v.sendData.email.$dirty,
              'field__label--invalid':
                ($v.sendData.email.$dirty && !$v.sendData.email.required) ||
                ($v.sendData.email.$dirty && !$v.sendData.email.email)
            }"
          >
            <div class="field__title">E-mail</div>
            <input v-model="$v.sendData.email.$model" class="field__input" type="text" placeholder="E-mail" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.password.$model && $v.sendData.password.$dirty && $v.sendData.password.minLength,
              'field__label--invalid':
                ($v.sendData.password.$dirty && !$v.sendData.password.required) ||
                ($v.sendData.password.$dirty && !$v.sendData.password.minLength)
            }"
          >
            <div class="field__title">Password</div>
            <input v-model="$v.sendData.password.$model" class="field__input" type="text" placeholder="Password" />
          </label>
        </div>
      </div>
      <div class="sidebar__row sidebar__row--last">
        <div class="sidebar__row-title">Availability for Shifts</div>
        <div class="sidebar__row-shifts">
          <div v-for="shift in shifts" :key="shift.id" class="sidebar__row-shift">
            <div class="shift__round" :style="{backgroundColor: shift.color}"></div>
            <div class="shift__title">{{ shift.name }}</div>
            <div class="shift__switch">
              <ToggleButton v-model="shift.active" color="#0290FC" :width="40" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div class="button button--fill" @click="createDriver">
          <span v-if="data">Save Changes</span>
          <span v-else>Create Driver</span>
        </div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'
import {required, email, minLength, requiredIf, helpers} from 'vuelidate/lib/validators'
const phoneReg = helpers.regex('phoneReg', /^[2-9]\d{2} \d{3} \d{4}$/)

export default {
  name: 'AddDriver',
  components: {ToggleButton},
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isLoading: false,
    sendData: {
      name: '',
      username: '',
      phone: '',
      email: '',
      password: ''
    }
  }),
  computed: {
    shifts() {
      return this.$store.getters['shifts/shifts'].map(shift => {
        const changedShift = {...shift}
        if (this.data?.shifts.find(sh => sh.id === shift.id)) {
          changedShift.active = true
        } else {
          changedShift.active = false
        }
        return changedShift
      })
    },
    activeShifts() {
      return this.shifts.filter(shift => shift.active).map(shift => shift.id)
    }
  },
  created() {
    if (!this.$store.getters['shifts/shifts'].length) this.$store.dispatch('shifts/fetch')
    if (this.data) {
      this.sendData = Object.assign(this.sendData, this.data)
    }
  },
  methods: {
    async createDriver() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const sendData = {
        name: this.sendData.name,
        phone: this.sendData.phone,
        username: this.sendData.username,
        email: this.sendData.email,
        password: this.sendData.password,
        shifts: this.activeShifts
      }
      try {
        if (this.data) {
          sendData.id = this.data.id
          await this.$store.dispatch('drivers/update', sendData)
        } else {
          await this.$store.dispatch('drivers/create', sendData)
        }
        this.$root.$emit('ShowSidebar', null)
      } catch (e) {
        if (e.response.data.error) {
          alert(e.response.data.error)
        } else {
          alert(e.response.data.errors[0].error)
        }
      }
    }
  },
  validations: {
    sendData: {
      name: {required},
      username: {required},
      email: {required, email},
      password: {
        required: requiredIf(function() {
          if (!this.data) return true
        }),
        minLength: minLength(8)
      },
      phone: {
        required: requiredIf(function() {
          if (this.sendData.phone) return true
        }),
        phoneReg
      }
    },
    activeShifts: {}
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
