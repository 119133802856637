import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    shifts: [],
    shift: {},
    pagination: {},
    page: 1
  },
  mutations: {
    setShifts(state, shifts) {
      state.shifts = _.unionBy(state.shifts, shifts, 'id')
    },
    change(state, item) {
      if (typeof item === 'object') {
        state.shifts = state.shifts.map(shift => {
          if (shift.id === item.id) {
            return Object.assign(shift, item)
          } else {
            return shift
          }
        })
      } else if (typeof item === 'number') {
        const shiftPosition = state.shifts.findIndex(sh => sh.id === item)
        state.shifts.splice(shiftPosition, 1)
        state.pagination.total--
      }
    },
    setShift(state, shift) {
      state.shift = shift
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.shifts = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    }
  },
  actions: {
    async fetch({commit, getters}) {
      let page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const shifts = await axios.get(url + '/shifts', {
          params: {page}
        })
        commit('pagination', shifts.data.result[1])
        commit('setShifts', shifts.data.result[0])
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async create({commit, dispatch}, shift) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.post(url + '/shifts', shift)
        commit('changePage', 1)
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async update({commit}, shift) {
      const id = shift.id
      delete shift.id
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + '/shifts/' + id, shift)
        shift.id = id
        commit('change', shift)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async activate({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/shifts/' + id + '/activate')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async deactivate({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/shifts/' + id + '/deactivate')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async delete({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.delete(url + '/shifts/' + id)
        // commit('reset')
        // dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    // async delActions({commit, dispatch}, id) {
    //   try {
    //     const url = process.env.VUE_APP_BACKEND
    //     await axios.delete(url + '/actions/' + id)
    //     commit('resetActions')
    //     dispatch('fetchActions')
    //   } catch (e) {
    //     commit('setError', e)
    //   }
    // },
    // async editAction({commit, dispatch}, {id, data}) {
    //   try {
    //     const url = process.env.VUE_APP_BACKEND
    //     await axios.put(url + '/actions/' + id, data)
    //     commit('resetActions')
    //     await dispatch('fetchActions')
    //   } catch (e) {
    //     commit('setError', e)
    //     throw e
    //   }
    // }
  },
  getters: {
    shifts: s => s.shifts,
    shift: s => s.shift,
    pagination: s => s.pagination,
    page: s => s.page
  }
}
