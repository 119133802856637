import axios from 'axios'

export default {
  namespaced: true,

  state: {
    companies: [],
    company: {},
    pagination: {},
    page: 1
  },
  mutations: {
    setCompanies(state, companies) {
      state.companies = state.companies.concat(companies)
    },
    change(state, office) {
      state.companies = state.companies.map(company => {
        if (company.id === office.id) {
          return Object.assign(company, office)
        } else {
          return company
        }
      })
    },
    setCompany(state, company) {
      state.company = company
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.companies = []
      state.page = 1
    },
    changePage(state) {
      state.page++
    }
  },
  actions: {
    async fetch({commit, getters}) {
      let page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const companies = await axios.get(url + '/companies', {
          params: {page, perPage: 24}
        })
        commit('pagination', companies.data.pagination)
        commit('setCompanies', companies.data.companies)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async create({commit, dispatch}, company) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.post(url + '/companies', company)
        commit('reset')
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async update({commit}, company) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + '/companies/' + company.id, company)
        commit('change', company)
        // commit('reset')
        // await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async activate({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/companies/' + id + '/activate')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async deactivate({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/companies/' + id + '/deactivate')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
    // async delActions({commit, dispatch}, id) {
    //   try {
    //     const url = process.env.VUE_APP_BACKEND
    //     await axios.delete(url + '/actions/' + id)
    //     commit('resetActions')
    //     dispatch('fetchActions')
    //   } catch (e) {
    //     commit('setError', e)
    //   }
    // },
    // async editAction({commit, dispatch}, {id, data}) {
    //   try {
    //     const url = process.env.VUE_APP_BACKEND
    //     await axios.put(url + '/actions/' + id, data)
    //     commit('resetActions')
    //     await dispatch('fetchActions')
    //   } catch (e) {
    //     commit('setError', e)
    //     throw e
    //   }
    // }
  },
  getters: {
    companies: s => s.companies,
    company: s => s.company,
    pagination: s => s.pagination,
    page: s => s.page
  }
}
