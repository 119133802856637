import axios from 'axios'

export default {
  namespaced: true,

  state: {
    hasBlock: false
  },
  mutations: {
    setBlock(state, block) {
      state.hasBlock = block
    }
  },
  actions: {
    async getBlockInfo({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const hasBlock = await axios.get(url + `/operations/has-block`)
        commit('setBlock', hasBlock.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async createBlock({commit, dispatch}, block) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.post(url + `/operations/block`, block)
        await dispatch('getBlockInfo')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async updateBlock({commit, dispatch}, block) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + `/operations/block`, block)
        await dispatch('getBlockInfo')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async clearBlocks({commit, dispatch}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.delete(url + `/operations/clear-blocks`)
        await dispatch('getBlockInfo')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async byPassBlock({commit, dispatch}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + `/operations/bypass`)
        await dispatch('getBlockInfo')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async byPassEnd({commit, dispatch}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + `/operations/end-bypass`)
        await dispatch('getBlockInfo')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
  }
}
