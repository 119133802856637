import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    deliveries: [],
    pagination: {},
    page: 1
  },
  mutations: {
    set(state, deliveries) {
      state.deliveries = _.unionBy(state.deliveries, deliveries, 'id')
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.deliveries = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    }
  },
  actions: {
    async fetch({commit, state}, id) {
      const page = state.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const deliveries = await axios.get(url + `/deliveries/customer/${id}/`, {
          params: {page}
        })
        commit('set', deliveries.data.deliveries)
        commit('pagination', deliveries.data.pagination)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  }
}
