import posts from './posts'
import comments from './comments'
import customers from './customers'
import auction from './auction'

export default {
  namespaced: true,
  modules: {
    posts,
    comments,
    customers,
    auction
  }
}
