<template>
  <div class="login__wrapper">
    <router-view />
    <div class="login__bg"></div>
    <div class="login__bg" :class="{'login__bg--blur': isBlur}"></div>
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
  data() {
    return {
      isBlur: true
    }
  },
  computed: {
    error() {
      return this.$store.getters.error
    }
  },
  watch: {
    error(error) {
      let err = null
      if (error.data.error) err = error.data.error
      else if (error?.data?.errors[0]?.error) err = error.data.errors[0].error
      this.$error(err)
    }
  },
  created() {
    setTimeout(() => {
      this.isBlur = false
    }, 1000)
  }
}
</script>

<style lang="scss" scss>
.login {
  &__wrapper {
  }
  &__bg {
    background-image: url('../../assets/img/bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // transition: filter .3s;
    // transition: .3s;
    // filter: blur(0);
    // &--blur {
    //   filter: blur(8px);
    // }
    // &:hover {
    //   filter: blur(0);
    // }
    // &--blur {
    //   &::after {
    //     transition: 0.5s ease-in-out;
    //     backdrop-filter: blur(5px);
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //   }
    //   // &:hover::after {
    //   //   backdrop-filter: blur(0);
    //   // }
    // }
  }
}
</style>
