<template>
  <div class="modal__wrapper">
    <div class="onboarding__gif-wrapper">
      <div class="onboarding__gif-inner"></div>
    </div>
    <div class="onboarding__wrapper">
      <div class="onboarding__header">
        <div class="onboarding__step">{{ step }}</div>
        <div class="onboarding__title">{{ stepText }}</div>
        <div class="onboarding__subtitle">Book Order with STS</div>
      </div>
      <div class="onboarding__main">
        <div v-if="step === 1" class="onboarding__message">
          <div class="onboarding__message-icon onboarding__message-icon--red">i</div>
          <div class="onboarding__message-text">
            Temporarily, if you book the order you must pay. Third party billing will be available soon.
          </div>
        </div>
        <div v-if="step === 1" class="onboarding__message">
          <div class="onboarding__message-icon">i</div>
          <div class="onboarding__message-text">
            Use the button <span class="onboarding__button--info">choose me</span> to quickly select yourself as the
            pick up or drop off.
          </div>
        </div>
        <div v-if="step === 2" class="onboarding__message">
          <div class="onboarding__message-icon">i</div>
          <div class="onboarding__message-text">
            Adding a picture is a great way to make the driver knows exactly what they are looking for.
          </div>
        </div>
        <div v-if="step === 3" class="onboarding__message">
          <div class="onboarding__message-icon onboarding__message-icon--green">i</div>
          <div class="onboarding__message-text">
            Debit cards are accepted.
          </div>
        </div>
        <div v-if="step === 3" class="onboarding__message">
          <div class="onboarding__message-icon">i</div>
          <div class="onboarding__message-text">
            Save as many debit & credit cards as you want.
          </div>
        </div>
        <div v-if="step === 4" class="onboarding__message">
          <div class="onboarding__message-icon">i</div>
          <div class="onboarding__message-text">
            To keep your book keeping organized, add a payment method for personal, the farm, and the shop.
          </div>
        </div>
      </div>
      <div class="onboarding__footer">
        <div v-if="step === 4 && !helpModal" class="onboarding__switch">
          <div class="onboarding__switch-text">Do not show tutorial again</div>
          <el-switch v-model="showSteps"> </el-switch>
        </div>
        <button
          class="button button--fill onboarding__button"
          :class="{'onboarding__button--green': step === 4}"
          @click="nextStep"
        >
          {{ buttonText }}
        </button>
        <div class="onboarding__dots">
          <div class="onboarding__dot" :class="{'onboarding__dot--blue': step === 1}"></div>
          <div class="onboarding__dot" :class="{'onboarding__dot--blue': step === 2}"></div>
          <div class="onboarding__dot" :class="{'onboarding__dot--blue': step === 3}"></div>
          <div class="onboarding__dot" :class="{'onboarding__dot--green': step === 4}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Onboarding',
  props: {
    helpModal: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      step: 1,
      stepText: 'Choose pick up and drop location',
      buttonText: 'Next',
      showSteps: false
    }
  },
  methods: {
    async nextStep() {
      this.step++
      switch (this.step) {
        case 1:
          this.stepText = 'Choose pick up and drop location'
          break
        case 2:
          this.stepText = 'Add a note or image'
          break
        case 3:
          this.stepText = 'Add a payment method'
          break
        case 4:
          this.stepText = 'Organize Payment Methods'
          this.buttonText = 'Finish tutorial'
          break
      }
      if (this.step > 4) {
        if (this.showSteps) await this.$store.dispatch('checkOnboarding')
        this.$modal.hide('OnboardingModal')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
