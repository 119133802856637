<template>
  <div class="sidebar__main">
    <div class="sidebar__title">
      <span v-if="data">Edit - {{ data.businessName }}</span>
      <span v-else>Add New Customer</span>
    </div>
    <div class="sidebar__main-inner">
      <div class="sidebar__row">
        <div class="sidebar__row-title">Basic Data</div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.businessName.$model && $v.sendData.businessName.$dirty,
              'field__label--invalid': $v.sendData.businessName.$dirty && !$v.sendData.businessName.required
            }"
          >
            <div class="field__title">Business Name</div>
            <input
              v-model="$v.sendData.businessName.$model"
              class="field__input"
              type="text"
              placeholder="Business Name"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.contactEmail.$model && $v.sendData.contactEmail.email && $v.sendData.contactEmail.$dirty,
              'field__label--invalid':
                $v.sendData.contactEmail.$dirty &&
                (!$v.sendData.contactEmail.required || !$v.sendData.contactEmail.email)
            }"
          >
            <div class="field__title">Contact Email</div>
            <input
              v-model="$v.sendData.contactEmail.$model"
              class="field__input"
              type="text"
              placeholder="Contact Email"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.firstName.$model && $v.sendData.firstName.$dirty,
              'field__label--invalid': $v.sendData.firstName.$dirty && !$v.sendData.firstName.required
            }"
          >
            <div class="field__title">First Name</div>
            <input v-model="$v.sendData.firstName.$model" class="field__input" type="text" placeholder="First Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.lastName.$model && $v.sendData.lastName.$dirty,
              'field__label--invalid': $v.sendData.lastName.$dirty && !$v.sendData.lastName.required
            }"
          >
            <div class="field__title">Last Name</div>
            <input v-model="$v.sendData.lastName.$model" class="field__input" type="text" placeholder="Last Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.nickName.$model && $v.sendData.nickName.$dirty
            }"
          >
            <div class="field__title">Nick Name</div>
            <input v-model="$v.sendData.nickName.$model" class="field__input" type="text" placeholder="Nick Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.middleInitial.$model && $v.sendData.middleInitial.$dirty
            }"
          >
            <div class="field__title">Middle Initial</div>
            <input
              v-model="$v.sendData.middleInitial.$model"
              v-mask="'AA'"
              class="field__input field__input--uppercase"
              type="text"
              placeholder="MI"
            />
          </label>
        </div>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Contact Info</div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.cellPhone.$model && $v.sendData.cellPhone.$dirty && $v.sendData.cellPhone.phoneReg,
              'field__label--invalid':
                ($v.sendData.cellPhone.$dirty && !$v.sendData.cellPhone.required) ||
                ($v.sendData.cellPhone.$dirty && !$v.sendData.cellPhone.phoneReg)
            }"
          >
            <div class="field__title">Cell Phone</div>
            <input
              v-model="$v.sendData.cellPhone.$model"
              v-mask="'### ### ####'"
              class="field__input"
              type="text"
              placeholder="Cell Phone"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.homePhone.$model && $v.sendData.homePhone.$dirty && $v.sendData.homePhone.phoneReg,
              'field__label--invalid':
                ($v.sendData.homePhone.$dirty && !$v.sendData.homePhone.required) ||
                ($v.sendData.homePhone.$dirty && !$v.sendData.homePhone.phoneReg)
            }"
          >
            <div class="field__title">Home Phone</div>
            <input
              v-model="$v.sendData.homePhone.$model"
              v-mask="'### ### ####'"
              class="field__input"
              type="text"
              placeholder="Home Phone"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.businessPhone.$model &&
                $v.sendData.businessPhone.$dirty &&
                $v.sendData.businessPhone.phoneReg,
              'field__label--invalid':
                ($v.sendData.businessPhone.$dirty && !$v.sendData.businessPhone.required) ||
                ($v.sendData.businessPhone.$dirty && !$v.sendData.businessPhone.phoneReg)
            }"
          >
            <div class="field__title">Business Phone</div>
            <input
              v-model="$v.sendData.businessPhone.$model"
              v-mask="'### ### ####'"
              class="field__input"
              type="text"
              placeholder="Business Phone"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.accountingEmail.$model &&
                $v.sendData.accountingEmail.$dirty &&
                $v.sendData.accountingEmail.email,
              'field__label--invalid':
                ($v.sendData.accountingEmail.$dirty && !$v.sendData.accountingEmail.required) ||
                ($v.sendData.accountingEmail.$dirty && !$v.sendData.accountingEmail.email)
            }"
          >
            <div class="field__title">Accounting E-mail</div>
            <input
              v-model="$v.sendData.accountingEmail.$model"
              class="field__input"
              type="text"
              placeholder="Accounting E-mail"
            />
          </label>
        </div>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Address Info</div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.lat.$model && $v.sendData.lat.$dirty,
              'field__label--invalid': $v.sendData.lat.$dirty && !$v.sendData.lat.required
            }"
          >
            <div class="field__title">Latitude</div>
            <input v-model="$v.sendData.lat.$model" class="field__input" type="text" placeholder="Latitude" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.lng.$model && $v.sendData.lng.$dirty,
              'field__label--invalid': $v.sendData.lng.$dirty && !$v.sendData.lng.required
            }"
          >
            <div class="field__title">Longitude</div>
            <input v-model="$v.sendData.lng.$model" class="field__input" type="text" placeholder="Longitude" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.country.$model && $v.sendData.country.$dirty,
              'field__label--invalid': $v.sendData.country.$dirty && !$v.sendData.country.required
            }"
          >
            <div class="field__title">Country</div>
            <input v-model="$v.sendData.country.$model" class="field__input" type="text" placeholder="Country" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.province.$model && $v.sendData.province.$dirty,
              'field__label--invalid': $v.sendData.province.$dirty && !$v.sendData.province.required
            }"
          >
            <div class="field__title">Province</div>
            <input v-model="$v.sendData.province.$model" class="field__input" type="text" placeholder="Province" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.city.$model && $v.sendData.city.$dirty,
              'field__label--invalid': $v.sendData.city.$dirty && !$v.sendData.city.required
            }"
          >
            <div class="field__title">City</div>
            <input v-model="$v.sendData.city.$model" class="field__input" type="text" placeholder="City" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.postalCode.$model && $v.sendData.postalCode.$dirty,
              'field__label--invalid':
                ($v.sendData.postalCode.$dirty && !$v.sendData.postalCode.required) ||
                ($v.sendData.postalCode.$dirty && !$v.sendData.postalCode.minLength)
            }"
          >
            <div class="field__title">Postal Code</div>
            <input v-model="$v.sendData.postalCode.$model" class="field__input" type="text" placeholder="Postal Code" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.northSouth.$model && $v.sendData.northSouth.$dirty,
              'field__label--invalid': $v.sendData.northSouth.$dirty && !$v.sendData.northSouth.required
            }"
          >
            <div class="field__title">North/South</div>
            <el-switch
              v-model="$v.sendData.northSouth.$model"
              active-text="North"
              inactive-text="South"
              class="el-switch--field"
            />
          </label>
          <label
            class="field__label field__label--full"
            :class="{
              'field__label--filled': $v.sendData.address.$model && $v.sendData.address.$dirty,
              'field__label--invalid': $v.sendData.address.$dirty && !$v.sendData.address.required
            }"
          >
            <div class="field__title">Address</div>
            <input v-model="$v.sendData.address.$model" class="field__input" type="text" placeholder="Address" />
          </label>
        </div>
      </div>
      <div class="sidebar__row" :class="{'sidebar__row--last': !isEnterprise}">
        <div class="sidebar__row-title">User Info</div>
        <div class="sidebar__row-main">
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.user.password.$model &&
                $v.sendData.user.password.$dirty &&
                $v.sendData.user.password.minLength,
              'field__label--invalid':
                ($v.sendData.user.password.$dirty && !$v.sendData.user.password.required) ||
                ($v.sendData.user.password.$dirty && !$v.sendData.user.password.minLength)
            }"
          >
            <div class="field__title">Password</div>
            <input v-model="sendData.user.password" class="field__input" type="text" placeholder="Password" />
          </label>
        </div>
      </div>
      <div v-if="isEnterprise" class="sidebar__row sidebar__row--last">
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label"
            :class="{
              'field__label--filled': $v.sendData.accountingName.$model && $v.sendData.accountingName.$dirty,
              'field__label--invalid': $v.sendData.accountingName.$dirty && !$v.sendData.accountingName.required
            }"
          >
            <div class="field__title">Accounting Name</div>
            <input
              v-model="$v.sendData.accountingName.$model"
              class="field__input"
              type="text"
              placeholder="Accounting Name"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled':
                $v.sendData.accountingPhone.$model &&
                $v.sendData.accountingPhone.$dirty &&
                $v.sendData.accountingPhone.phoneReg,
              'field__label--invalid':
                ($v.sendData.accountingPhone.$dirty && !$v.sendData.accountingPhone.required) ||
                ($v.sendData.accountingPhone.$dirty && !$v.sendData.accountingPhone.phoneReg)
            }"
          >
            <div class="field__title">Accounting Phone</div>
            <input
              v-model="$v.sendData.accountingPhone.$model"
              v-mask="'### ### ####'"
              class="field__input"
              type="text"
              placeholder="Accounting Phone"
            />
          </label>
        </div>
        <div class="sidebar__row-main">
          <div class="sidebar__employees">
            <div class="field__label-employees">
              <input v-model="newEmployee" class="field__input" type="text" placeholder="New Employee" />
              <button class="button button--big" @click="addEmployee">
                <i class="ri-add-circle-fill"></i>
              </button>
            </div>
            <div class="sidebar__employees-list">
              <div v-for="(empl, idx) of sendData.employeeNames" :key="empl + idx" class="sidebar__employees-item">
                <span>{{ empl }}</span>
                <button class="icon-button icon-button--round">
                  <i class="ri-delete-bin-7-fill" @click="delEmployee(empl)"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div class="button button--fill" @click="createCustomer">Save</div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, email, minLength, requiredIf, helpers} from 'vuelidate/lib/validators'
import _ from 'lodash'
const phoneReg = helpers.regex('phoneReg', /^[2-9]\d{2} \d{3} \d{4}$/)

export default {
  name: 'AddCustomer',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isEnterprise: false,
    sendData: {
      firstName: '',
      lastName: '',
      contactEmail: '',
      businessName: '',
      nickName: '',
      middleInitial: '',
      accountingEmail: '',
      cellPhone: '',
      homePhone: '',
      businessPhone: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
      lat: '',
      lng: '',
      northSouth: false,
      user: {
        password: ''
      },
      isEnterprise: false,
      accountingName: '',
      accountingPhone: '',
      employeeNames: []
    },
    newEmployee: null
  }),
  created() {
    if (this.$route.name === 'EnterpriseCustomers') {
      this.isEnterprise = true
      this.sendData.isEnterprise = true
    }
    if (this.data) {
      if (!this.data.user) delete this.data.user
      _.assign(this.sendData, this.data)
    }
  },
  methods: {
    async createCustomer() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const sendData = {
        cellPhone: this.sendData.cellPhone,
        northSouth: this.sendData.northSouth,
        isEnterprise: this.isEnterprise
      }
      this.sendData.businessName ? (sendData.businessName = this.sendData.businessName) : null
      this.sendData.nickName ? (sendData.nickName = this.sendData.nickName) : null
      this.sendData.middleInitial ? (sendData.middleInitial = this.sendData.middleInitial) : null
      this.sendData.homePhone ? (sendData.homePhone = this.sendData.homePhone) : null
      this.sendData.businessPhone ? (sendData.businessPhone = this.sendData.businessPhone) : null
      this.sendData.address ? (sendData.address = this.sendData.address) : null
      this.sendData.city ? (sendData.city = this.sendData.city) : null
      this.sendData.province ? (sendData.province = this.sendData.province) : null
      this.sendData.country ? (sendData.country = this.sendData.country) : null
      this.sendData.postalCode ? (sendData.postalCode = this.sendData.postalCode) : null
      this.sendData.lat ? (sendData.lat = this.sendData.lat) : null
      this.sendData.lng ? (sendData.lng = this.sendData.lng) : null
      this.sendData.firstName ? (sendData.firstName = this.sendData.firstName) : null
      this.sendData.lng ? (sendData.lng = this.sendData.lng) : null
      this.sendData.lastName ? (sendData.lastName = this.sendData.lastName) : null
      this.sendData.contactEmail ? (sendData.contactEmail = this.sendData.contactEmail) : null
      this.sendData.accountingEmail ? (sendData.accountingEmail = this.sendData.accountingEmail) : null

      this.sendData.user.password || this.sendData.isEnterprise ? (sendData.user = {}) : null
      this.sendData.user.password ? (sendData.user.password = this.sendData.user.password) : null

      this.sendData.accountingName && this.sendData.isEnterprise
        ? (sendData.accountingName = this.sendData.accountingName)
        : null
      this.sendData.accountingPhone && this.sendData.isEnterprise
        ? (sendData.accountingPhone = this.sendData.accountingPhone)
        : null
      this.sendData.employeeNames.length && this.sendData.isEnterprise
        ? (sendData.employeeNames = this.sendData.employeeNames)
        : null

      try {
        if (this.data) {
          sendData.id = this.data.id
          await this.$store.dispatch('customers/update', sendData)
        } else {
          await this.$store.dispatch('customers/create', sendData)
        }
        this.$root.$emit('ShowSidebar', null)
      } catch (e) {
        if (e.response.data.error) {
          alert(e.response.data.error)
        } else {
          alert(e.response.data.errors[0].error)
        }
      }
    },
    addEmployee() {
      this.sendData.employeeNames.push(this.newEmployee)
      this.newEmployee = ''
    },
    delEmployee(name) {
      this.sendData.employeeNames = this.sendData.employeeNames.filter(n => n !== name)
    }
  },
  validations: {
    sendData: {
      firstName: {required: false},
      lastName: {required: false},
      nickName: {required: false},
      middleInitial: {required: false},
      contactEmail: {required: false, email},
      businessName: {
        required: requiredIf(function() {
          if (!this.sendData.firstName && !this.sendData.lastName) return true
        })
      },
      accountingEmail: {required: false, email},
      cellPhone: {required, phoneReg},
      homePhone: {required: false, phoneReg},
      businessPhone: {required: false, phoneReg},
      address: {required: false},
      city: {required: false},
      province: {required: false},
      postalCode: {required: false, minLength: minLength(5)},
      country: {required: false},
      lat: {required: false},
      lng: {required: false},
      northSouth: {required: false},
      user: {
        password: {
          required: false,
          minLength: minLength(8)
        }
      },

      isEnterprise: {required: false},
      accountingPhone: {
        required: requiredIf(function() {
          if (this.isEnterprise) return true
        }),
        phoneReg
      },
      accountingName: {
        required: requiredIf(function() {
          if (this.isEnterprise) return true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
