import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    customers: [],
    customer: {},
    pagination: {},
    page: 1,
    searchParam: null
  },
  mutations: {
    set(state, customers) {
      state.customers = _.unionBy(state.customers, customers, 'id')
    },
    setCustomer(state, customer) {
      state.customer = customer
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.customers = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    },
    changeSearch(state, param) {
      state.searchParam = param
    }
  },
  actions: {
    async fetch({commit, state}, params) {
      const page = state.page
      const searchParam = {
        'filters[generalSearch]': state.searchParam,
        'filters[publicOrCompanyID]': process.env.VUE_APP_COMPANY_ID
      }
      try {
        const url = process.env.VUE_APP_BACKEND
        const customers = await axios.get(url + '/community/directory', {
          params: {...params, page, perPage: 24, ...searchParam}
        })
        commit('pagination', customers.data.pagination)
        commit('set', customers.data.data)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchCustomer({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const customer = await axios.get(url + '/community/directory/' + id)
        commit('setCustomer', customer.data.result)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  }
}
