<template>
  <div class="sidebar__main">
    <div class="sidebar__title">
      <span>Create Deliveries Data Export</span>
    </div>
    <div class="sidebar__main-inner">
      <div class="sidebar__row">
        <div class="sidebar__row-main">
          <label
            class="field__label"
            :class="{
              'field__label--filled': dataExport.date,
              'field__label--invalid': $v.dataExport.date.$dirty && !$v.dataExport.date.required
            }"
          >
            <div class="field__title">Date</div>
            <date-picker
              v-model="$v.dataExport.date.$model"
              format="YYYY-MM"
              value-type="format"
              placeholder="Select date"
              type="month"
              :lang="lang"
              popup-class="new-order-date-popup"
              class="new-order-date"
            >
              <template slot="icon-calendar">
                <i class="ri-calendar-fill"></i>
              </template>
            </date-picker>
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': dataExport.invoiceNo,
              'field__label--invalid':
                ($v.dataExport.invoiceNo.$dirty && !$v.dataExport.invoiceNo.required) ||
                ($v.dataExport.invoiceNo.$dirty && !$v.dataExport.invoiceNo.numeric)
            }"
          >
            <div class="field__title">Invoice No</div>
            <input v-model="$v.dataExport.invoiceNo.$model" class="field__input" type="tel" placeholder="Invoice No" />
          </label>

          <label
            class="field__label"
            :class="{
              'field__label--filled': dataExport.fuelSurCharge,
              'field__label--invalid':
                ($v.dataExport.fuelSurCharge.$dirty && !$v.dataExport.fuelSurCharge.required) ||
                ($v.dataExport.fuelSurCharge.$dirty && !$v.dataExport.fuelSurCharge.decimal)
            }"
          >
            <div class="field__title">Fuel SurCharge</div>
            <input v-model="$v.dataExport.fuelSurCharge.$model" class="field__input" placeholder="Fuel SurCharge" />
          </label>

          <div class="sidebar__row-shifts">
            <div class="shift__title">Only export enterprise deliveries data</div>
            <div class="shift__switch">
              <ToggleButton v-model="dataExport.enterpriseOnly" color="#0290FC" :width="40" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div class="button button--fill" @click="create">
          <span>Create Export</span>
        </div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, numeric, decimal} from 'vuelidate/lib/validators'
import DatePicker from 'vue2-datepicker'
import {mapActions, mapMutations} from 'vuex'
import {ToggleButton} from 'vue-js-toggle-button'

export default {
  name: 'AddDataExport',
  components: {DatePicker, ToggleButton},
  data: () => ({
    dataExport: {
      invoiceNo: null,
      fuelSurCharge: null,
      date: null,
      enterpriseOnly: false
    },
    lang: {
      formatLocale: {
        firstDayOfWeek: 1
      }
    }
  }),
  methods: {
    ...mapActions({
      createExport: 'deliveries/exports/create',
      fetch: 'deliveries/exports/fetch'
    }),
    ...mapMutations({
      reset: 'deliveries/exports/reset'
    }),
    async create() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      await this.createExport(this.dataExport)
      this.reset()
      this.fetch()
      this.$root.$emit('ShowSidebar', null)
    }
  },
  validations: {
    dataExport: {
      invoiceNo: {required, numeric},
      fuelSurCharge: {required, decimal},
      date: {required}
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
