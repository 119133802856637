<template>
  <div class="delivery__wrapper">
    <div class="delivery__header">
      <div class="header__left">
        <div class="delivery__indicator indicator indicator--blue indicator--fill">Route: #12</div>
        <div class="delivery__indicator indicator indicator--blue">North Shift</div>
        <div class="delivery__indicator indicator indicator--blue">Day Skid Rate</div>
      </div>
      <div class="header__right">
        <div class="button button--icon" @click="showDelivery">
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.75 11.7H1.3V3.25H6.99401L8.29401 1.95H0.65C0.2925 1.95 0 2.2425 0 2.6V12.35C0 12.7075 0.2925 13 0.65 13H10.4C10.7575 13 11.05 12.7075 11.05 12.35V4.706L9.75 6.006V11.7ZM12.35 0H7.8C7.4425 0 7.15 0.2925 7.15 0.65C7.15 1.0075 7.4425 1.3 7.8 1.3H10.7835L6.03849 6.0385C5.92149 6.1555 5.85 6.318 5.85 6.5C5.85 6.8575 6.1425 7.15 6.5 7.15C6.682 7.15 6.84451 7.0785 6.96151 6.9615L11.7 2.2165V5.2C11.7 5.5575 11.9925 5.85 12.35 5.85C12.7075 5.85 13 5.5575 13 5.2V0.65C13 0.2925 12.7075 0 12.35 0Z"
              fill="#172B4D"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="delivery__stations">
      <div class="station__wrapper">
        <div class="station__indicator indicator--round indicator--orange"></div>
        <div class="station__name">Rodrix Fasteners Ltd.</div>
        <div class="station__address indicator indicator--grey">8 Brett’s Street</div>
      </div>
      <div class="station__wrapper">
        <div class="station__indicator indicator--round indicator--orange"></div>
        <div class="station__name">Rodrix Fasteners Ltd.</div>
        <div class="station__address indicator indicator--grey">943 South Victoria Ave.</div>
      </div>
      <div class="station__wrapper">
        <div class="station__indicator indicator--round indicator--blue"></div>
        <div class="station__name">Rodrix Fasteners Ltd.</div>
        <div class="station__address indicator indicator--grey">9160 Pilgrim Drive Suite 97</div>
      </div>
      <div class="station__wrapper">
        <div class="station__indicator indicator--round indicator--blue"></div>
        <div class="station__name">Rodrix Fasteners Ltd.</div>
        <div class="station__address indicator indicator--grey">529 Marsh Drive Chatham ...</div>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryInfo from '../DeliveryInfo'

export default {
  name: 'DeliveryCard',
  methods: {
    showDelivery() {
      const style = this.$modal.styles
      // this.active = !this.active
      this.$modal.show(
        DeliveryInfo,
        {},
        {...style},
        {
          'before-open': this.$modal.open,
          'before-close': this.$modal.close
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
