<template>
  <div class="sidebar__main">
    <Loader v-if="isLoading" />
    <div class="sidebar__title">
      <span v-if="data">Edit Delivery</span>
      <span v-else>Add New Delivery</span>
    </div>
    <div class="sidebar__main-inner">
      <div class="sidebar__row">
        <div class="sidebar__row-title">Choose Service</div>
        <div class="sidebar__row-dropdown" :class="{'sidebar__row-dropdown--disabled': isDisabled}">
          <div class="sidebar__row-dropdown-icon">
            <div class="icon-button icon-button--round icon-button--blue">
              <i class="ri-stack-fill"></i>
            </div>
          </div>
          <Multiselect
            v-model="$v.service.$model"
            :options="services"
            :placeholder="$v.service.$model ? $v.service.$model.name : 'Service'"
            class="select-custom"
            track-by="id"
            label="name"
            :show-labels="false"
            :allow-empty="false"
            :searchable="false"
            :disabled="isDisabled"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="label__item">
                <span class="label__title">{{ props.option.name }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__item">
                <div class="option__item-icon">
                  <i class="ri-checkbox-circle-fill"></i>
                </div>
                <div class="option__item-customer">
                  <div class="option__item-name">
                    <span>{{ props.option.name }} </span>
                  </div>
                </div>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Due Date</div>
        <div class="field__label--date">
          <i class="ri-calendar-todo-fill"></i>
          <input v-model="$v.date.$model" :min="localDate" type="date" class="field__date--mobile" />
        </div>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Pick-up</div>
        <div class="sidebar__row-dropdown" :class="{'sidebar__row-dropdown--disabled': isDisabled}">
          <div class="sidebar__row-dropdown-icon">
            <div class="icon-button icon-button--round icon-button--blue">
              <i class="ri-arrow-up-circle-line"></i>
            </div>
          </div>
          <div class="sidebar__row-dropdown-title" @click="openSearch({type: 'pickup'})">
            <span v-if="$v.pickup.$model">{{ $v.pickup.$model.businessName }}</span>
            <span v-else class="sidebar__row-dropdown-desc">Pickup destination</span>
          </div>
        </div>
      </div>
      <div v-for="(dropOff, index) in $v.dropOffs.$each.$iter" :key="index" class="sidebar__row">
        <div class="sidebar__row-title">Drop-off</div>
        <div class="sidebar__row-dropdown" :class="{'sidebar__row-dropdown--disabled': isDisabled}">
          <div class="sidebar__row-dropdown-icon">
            <div class="icon-button icon-button--round icon-button--orange">
              <i class="ri-arrow-down-circle-line"></i>
            </div>
          </div>
          <div class="sidebar__row-dropdown-title" @click="openSearch({type: 'dropOff', index})">
            <span v-if="dropOff.businessName.$model">{{ dropOff.businessName.$model }}</span>
            <span v-else class="sidebar__row-dropdown-desc">Drop-off destination</span>
          </div>
        </div>
      </div>
      <div v-if="data" class="sidebar__row">
        <div class="sidebar__row-title">Attachments</div>
        <label
          class="field__label field__label--text"
          :class="{'field__label--filled': $v.notes.$model && $v.notes.$dirty}"
        >
          <div class="field__title">Notes</div>
          <el-input v-model="$v.notes.$model" type="textarea" :rows="2" placeholder="Notes" resize="none" />
        </label>
        <div v-if="data.attachmentPath || photo" class="field__label--image">
          <el-image
            v-if="data.attachmentPath && !photo"
            :src="data.attachmentPath"
            :preview-src-list="[data.attachmentPath]"
            fit="cover"
          />
          <el-image v-if="photo" :src="photo" :preview-src-list="[photo]" fit="cover" />
        </div>
        <FilesUpload v-model="file" />
      </div>
      <div v-if="!data" class="sidebar__row-link" @click="addDropOff">
        + Add another Drop-off
      </div>
      <div v-if="dropOffError" class="sidebar__row-error">Enter last drop-off</div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Billing</div>
        <div class="sidebar__row-dropdown" :class="{'sidebar__row-dropdown--disabled': isDisabled}">
          <div class="sidebar__row-dropdown-icon">
            <div class="icon-button icon-button--round icon-button--blue">
              <i class="ri-money-dollar-circle-fill"></i>
            </div>
          </div>
          <div class="sidebar__row-dropdown-title" @click="openSearch({type: 'billing'})">
            <span v-if="$v.billing.$model">{{ $v.billing.$model.businessName }}</span>
            <span v-else class="sidebar__row-dropdown-desc">Billing destination</span>
          </div>
        </div>
      </div>
      <div class="sidebar__row sidebar__row--last">
        <div class="sidebar__row-title">Status</div>
        <div class="field__label--swich">
          <div class="swich__title">
            <div class="round round--red"></div>
            <span>Not Done</span>
            <div class="round round--green"></div>
            <span>Done</span>
          </div>
          <el-switch v-model="$v.status.$model" class="swich" />
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div v-if="data" class="button button--fill" @click="updateDelivery">Save</div>
        <div v-else class="button button--fill" @click="createDelivery">Save</div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchModal from './SearchCustomer'
import {required} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import FilesUpload from './FilesUpload'
import moment from 'moment'
import Loader from '@/components/Loader'
import {mapState, mapMutations, mapActions} from 'vuex'

export default {
  name: 'AddDelivery',
  components: {Multiselect, FilesUpload, Loader},
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    localDate: moment().format('YYYY-MM-DD'),
    isLoading: false,
    isDisabled: false,
    findCustomer: null,
    service: null,
    dropOffError: false,
    date: moment().format('YYYY-MM-DD'),
    status: false,
    notes: null,
    file: null,
    photo: null
  }),
  computed: {
    ...mapState({
      pickup: s => s.deliveries.pickup,
      billing: s => s.deliveries.billing,
      dropOffs: s => s.deliveries.dropOffs,
      delivery: s => s.deliveries.delivery,
      searchCustomers: s => s.customers.searchCustomers,
      services: s => s.services.services,
      servicesPagination: s => s.services.pagination
    })
  },
  watch: {
    file(file) {
      if (!file) {
        this.photo = null
        return
      }
      this.photo = window.URL.createObjectURL(file)
    }
  },
  async created() {
    if (!this.servicesPagination.total) {
      await this.fetchServices()
    }
    let data = this.data
    if (data?.search) {
      await this.fetchDelivery(this.data.id)
      data = this.delivery
    }
    if (data) {
      this.isDisabled = true
      this.date = moment.utc(data.routeDate).format('yyyy-MM-DD')
      this.status = !!data.status
      this.service = this.services.find(serv => serv.name === data.serviceName)
      this.selectPickup({businessName: data.pickUpName, id: data.pickUpID})
      this.selectBilling({businessName: data.billingName, id: data.billingID})
      this.selectDropOff({dropOff: {businessName: data.dropOffName, id: data.dropOffID}, index: 0})
      this.notes = data.notes
    }
  },
  methods: {
    ...mapMutations({
      selectPickup: 'deliveries/setPickup',
      selectBilling: 'deliveries/setBilling',
      selectDropOff: 'deliveries/setDropOff',
      resetDelivery: 'deliveries/resetDelivery'
    }),
    ...mapActions({
      fetchServices: 'services/fetch',
      fetchDelivery: 'deliveries/fetchDelivery'
    }),
    openSearch(params) {
      if (this.isDisabled) return
      this.$modal.display(
        SearchModal,
        {
          type: params.type,
          index: +params.index,
          pickup: this.$v.pickup.$model,
          dropOff: this.dropOff,
          billing: this.$v.billing.$model
        },
        {
          name: 'SearchPublicCustomerModal',
          transition: 'none',
          height: '100%',
          classes: 'modal modal-search-public-customer'
        }
      )
    },
    addDropOff() {
      if (this.dropOffs[this.dropOffs.length - 1].businessName) {
        this.selectDropOff({dropOff: {businessName: null}, index: this.dropOffs.length})
      } else {
        this.dropOffError = true
        setTimeout(() => {
          this.dropOffError = false
        }, 2000)
      }
    },
    async createDelivery() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const sendData = {
        routeDate: this.date,
        serviceID: this.service.id,
        billingID: this.billing.id,
        status: this.status ? 2 : 0,
        pickUpID: this.pickup.id,
        dropOffs: this.dropOffs.map(d => {
          return {
            id: d.id
          }
        })
      }
      try {
        this.isLoading = true
        await this.$store.dispatch('deliveries/create', sendData)
        this.$root.$emit('ShowSidebar', null)
        this.isLoading = false
      } catch (err) {
        if (err.response.data.error) {
          alert(err.response.data.error)
        } else {
          alert(err.response.data.errors[0].error)
        }
      }
    },
    async updateDelivery() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const sendData = new FormData()
      sendData.append('routeDate', this.date)
      sendData.append('serviceID', this.service.id)
      sendData.append('pickUpID', this.pickup.id)
      sendData.append('dropOffID', this.dropOffs[0].id)
      sendData.append('billingID', this.billing.id)
      sendData.append('status', this.status ? 2 : 0)
      this.notes ? sendData.append('notes', this.notes) : null
      this.file ? sendData.append('attachment', this.file) : null
      try {
        await this.$store.dispatch('deliveries/update', {delivery: sendData, id: this.data.id})
        this.$root.$emit('ShowSidebar', null)
      } catch (err) {
        if (err.response.data.error) {
          alert(err.response.data.error)
        } else {
          alert(err.response.data.errors[0].error)
        }
      }
    },
    async asyncFind(query) {
      await this.$store.dispatch('customers/search', {'filters[businessName]': query, perPage: 200})
    }
  },
  beforeDestroy() {
    this.resetDelivery()
  },
  validations: {
    date: {required},
    service: {required},
    billing: {required},
    status: {required},
    pickup: {required},
    notes: {},
    dropOffs: {
      required,
      $each: {
        businessName: {required}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
.field__label {
  position: relative;
  width: 100%;
}
</style>
