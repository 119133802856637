<template>
  <div class="sidebar__main">
    <Loader v-if="isLoading" />
    <div class="sidebar__title">
      <span v-if="data">Edit Delivery</span>
      <span v-else>Add New Delivery</span>
    </div>
    <div class="sidebar__main-inner">
      <div class="sidebar__row">
        <div class="sidebar__row-title">Choose Service</div>
        <div class="sidebar__row-dropdown">
          <div class="sidebar__row-dropdown-icon">
            <div class="icon-button icon-button--round icon-button--blue">
              <i class="ri-stack-fill"></i>
            </div>
          </div>
          <Multiselect
            v-model="$v.service.$model"
            :options="services"
            :placeholder="$v.service.$model ? $v.service.$model.name : 'Service'"
            class="select-custom"
            track-by="id"
            label="name"
            :show-labels="false"
            :allow-empty="false"
            :searchable="false"
            :disabled="isDisabled"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="label__item">
                <span class="label__title">{{ props.option.name }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__item">
                <div class="option__item-icon">
                  <i class="ri-checkbox-circle-fill"></i>
                </div>
                <div class="option__item-customer">
                  <div class="option__item-name">
                    <span>{{ props.option.name }} </span>
                  </div>
                </div>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Due Date</div>
        <date-picker
          v-model="$v.date.$model"
          format="YYYY-MM-DD"
          placeholder="Select date"
          type="date"
          :lang="lang"
          :disabled-date="disableWeekends"
          popup-class="delivery-date-popup"
          class="new-order-date"
        >
          <template slot="icon-calendar">
            <i class="ri-calendar-fill"></i>
          </template>
        </date-picker>
      </div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Pick-up</div>
        <div class="sidebar__row-dropdown">
          <div class="sidebar__row-dropdown-icon">
            <div class="icon-button icon-button--round icon-button--blue">
              <i class="ri-arrow-up-circle-line"></i>
            </div>
          </div>
          <Multiselect
            v-model="$v.pickup.$model"
            :options="searchCustomers"
            :placeholder="$v.pickup.$model ? $v.pickup.$model.businessName : 'Pick Up'"
            class="select-custom"
            track-by="id"
            label="businessName"
            :show-labels="false"
            :allow-empty="false"
            :disabled="isDisabled"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="label__item">
                <span class="label__title">{{ props.option.businessName }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__item">
                <div class="option__item-icon">
                  <i class="ri-checkbox-circle-fill"></i>
                </div>
                <div class="option__item-customer">
                  <div class="option__item-name">
                    <span v-if="props.option.fathersName">{{ props.option.fathersName }}’ </span>
                    <span>{{ props.option.firstName }} </span>
                    <span>{{ props.option.middleInitial }} </span>
                    <span>{{ props.option.lastName }} </span>
                    <span v-if="props.option.nickName">({{ props.option.nickName }})</span>
                  </div>
                  <div class="option__item-name">
                    <span>{{ props.option.businessName }}</span>
                  </div>
                  <div class="option__item-address">
                    <span>Address: </span>
                    <span>{{ props.option.address }} {{ props.option.city }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template slot="noOptions">
              <div class="label__item--options">
                <span class="label__title">Please type business name</span>
              </div>
            </template>
            <template slot="noResult">
              <div class="label__item--options">
                <span class="label__title">No business name like that, please try again</span>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>

      <div v-for="(dropOff, index) in $v.dropOffs.$each.$iter" :key="index" class="sidebar__row">
        <div class="sidebar__row-title">Drop-off</div>
        <div class="sidebar__row-dropdown">
          <div class="sidebar__row-dropdown-icon">
            <div class="icon-button icon-button--round icon-button--orange">
              <i class="ri-arrow-down-circle-line"></i>
            </div>
          </div>
          <Multiselect
            v-model="dropOff.dropOffName.$model"
            :options="searchCustomers"
            :placeholder="dropOff.dropOffName.$model ? dropOff.dropOffName.$model.businessName : 'Drop-off'"
            class="select-custom"
            track-by="id"
            label="businessName"
            :show-labels="false"
            :allow-empty="false"
            :disabled="isDisabled"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="label__item">
                <span class="label__title">{{ props.option.businessName }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__item">
                <div class="option__item-icon">
                  <i class="ri-checkbox-circle-fill"></i>
                </div>
                <div class="option__item-customer">
                  <div class="option__item-name">
                    <span v-if="props.option.fathersName">{{ props.option.fathersName }}’ </span>
                    <span>{{ props.option.firstName }} </span>
                    <span>{{ props.option.middleInitial }} </span>
                    <span>{{ props.option.lastName }} </span>
                    <span v-if="props.option.nickName">({{ props.option.nickName }})</span>
                  </div>
                  <div class="option__item-name">
                    <span>{{ props.option.businessName }}</span>
                  </div>
                  <div class="option__item-address">
                    <span>Address: </span>
                    <span>{{ props.option.address }} {{ props.option.city }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template slot="noOptions">
              <div class="label__item--options">
                <span class="label__title">Please type business name</span>
              </div>
            </template>
            <template slot="noResult">
              <div class="label__item--options">
                <span class="label__title">No business name like that, please try again</span>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>
      <div v-if="data" class="sidebar__row">
        <div class="sidebar__row-title">Attachments</div>
        <label
          class="field__label field__label--text"
          :class="{'field__label--filled': $v.notes.$model && $v.notes.$dirty}"
        >
          <div class="field__title">Notes</div>
          <el-input v-model="$v.notes.$model" type="textarea" :rows="2" placeholder="Notes" resize="none" />
        </label>
        <div v-if="data.attachmentPath || photo" class="field__label--image">
          <el-image
            v-if="data.attachmentPath && !photo"
            :src="data.attachmentPath"
            :preview-src-list="[data.attachmentPath]"
            fit="cover"
          />
          <el-image v-if="photo" :src="photo" :preview-src-list="[photo]" fit="cover" />
        </div>
        <FilesUpload v-model="file" />
      </div>
      <div v-if="!data" class="sidebar__row-link" @click="addDropOff">
        + Add another Drop-off
      </div>
      <div v-if="dropOffError" class="sidebar__row-error">Enter last drop-off</div>
      <div class="sidebar__row">
        <div class="sidebar__row-title">Billing</div>
        <div class="sidebar__row-dropdown">
          <div class="sidebar__row-dropdown-icon">
            <div class="icon-button icon-button--round icon-button--blue">
              <i class="ri-money-dollar-circle-fill"></i>
            </div>
          </div>
          <Multiselect
            v-model="$v.billing.$model"
            :options="searchCustomers"
            :placeholder="$v.billing.$model ? $v.billing.$model.businessName : 'Billing'"
            class="select-custom"
            track-by="id"
            label="businessName"
            :show-labels="false"
            :allow-empty="false"
            :disabled="isDisabled"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="label__item">
                <span class="label__title">{{ props.option.businessName }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__item">
                <div class="option__item-icon">
                  <i class="ri-checkbox-circle-fill"></i>
                </div>
                <div class="option__item-customer">
                  <div class="option__item-name">
                    <span v-if="props.option.fathersName">{{ props.option.fathersName }}’ </span>
                    <span>{{ props.option.firstName }} </span>
                    <span>{{ props.option.middleInitial }} </span>
                    <span>{{ props.option.lastName }} </span>
                    <span v-if="props.option.nickName">({{ props.option.nickName }})</span>
                  </div>
                  <div class="option__item-name">
                    <span>{{ props.option.businessName }}</span>
                  </div>
                  <!-- <div class="option__item-address">
                    <span>Address: </span>
                    <span>{{ props.option.address }} {{ props.option.city }}</span>
                  </div> -->
                </div>
              </div>
            </template>
            <template slot="noOptions">
              <div class="label__item--options">
                <span class="label__title">Please type business name</span>
              </div>
            </template>
            <template slot="noResult">
              <div class="label__item--options">
                <span class="label__title">No business name like that, please try again</span>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>
      <div class="sidebar__row sidebar__row--last">
        <div class="sidebar__row-title">Status</div>
        <div class="field__label--swich">
          <div class="swich__title">
            <div class="round round--red"></div>
            <span>Not Done</span>
            <div class="round round--green"></div>
            <span>Done</span>
          </div>
          <el-switch v-model="$v.status.$model" class="swich" />
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div v-if="data" class="button button--fill" @click="updateDelivery">Save</div>
        <div v-else class="button button--fill" @click="createDelivery">Save</div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchModal from './SearchCustomer'
import {required} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import FilesUpload from './FilesUpload'
import moment from 'moment'
import Loader from '@/components/Loader'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'AddDelivery',
  components: {Multiselect, FilesUpload, Loader, DatePicker},
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isLoading: false,
    isDisabled: false,
    findCustomer: null,
    service: null,
    pickup: null,
    dropOffs: [{dropOffName: null}],
    dropOffError: false,
    billing: null,
    date: new Date(),
    status: false,
    notes: null,
    file: null,
    photo: null,
    services: [],
    lang: {
      formatLocale: {
        firstDayOfWeek: 1
      }
    }
  }),
  computed: {
    searchCustomers() {
      return this.$store.getters['customers/searchCustomers']
    }
  },
  watch: {
    file(file) {
      if (!file) {
        this.photo = null
        return
      }
      this.photo = window.URL.createObjectURL(file)
    }
  },
  async created() {
    if (!this.$store.getters['services/pagination'].total) {
      await this.$store.dispatch('services/fetch')
    }
    this.$store.dispatch('customers/search').then(() => {
      data ? (this.billing = this.searchCustomers.find(c => c.id === data.billingID)) : null
    })
    this.services = this.$store.getters['services/services']
    let data = this.data
    if (data?.search) {
      await this.$store.dispatch('deliveries/fetchDelivery', this.data.id)
      data = this.$store.getters['deliveries/delivery']
    }
    if (data) {
      this.isDisabled = true
      this.date = moment.utc(data.routeDate).toDate()
      this.status = !!data.status
      this.service = this.services.find(serv => serv.name === data.serviceName)
      this.pickup = {businessName: data.pickUpName, id: data.pickUpID}
      this.dropOffs = [{dropOffName: {businessName: data.dropOffName, id: data.dropOffID}}]
      this.notes = data.notes
    }
  },
  methods: {
    openSearch(type) {
      this.$modal.display(
        SearchModal,
        {
          type,
          pickup: this.$v.pickup.$model,
          dropOff: this.dropOff,
          billing: this.$v.billing.$model
        },
        {
          name: 'SearchPublicCustomerModal',
          transition: 'none',
          height: '100%',
          classes: 'modal modal-search-public-customer'
        }
      )
    },
    addDropOff() {
      if (this.dropOffs[this.dropOffs.length - 1].dropOffName) {
        this.dropOffs.push({dropOffName: null})
      } else {
        this.dropOffError = true
        setTimeout(() => {
          this.dropOffError = false
        }, 2000)
      }
    },
    selectPickup(customer) {
      this.pickup = customer
    },
    async createDelivery() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const sendData = {
        routeDate: moment.utc(this.date).format('yyyy-MM-DD'),
        serviceID: this.service.id,
        billingID: this.billing.id,
        status: this.status ? 2 : 0,
        pickUpID: this.pickup.id,
        dropOffs: this.dropOffs.map(d => {
          return {
            id: d.dropOffName.id
          }
        })
      }
      try {
        this.isLoading = true
        await this.$store.dispatch('deliveries/create', sendData)
        this.$root.$emit('ShowSidebar', null)
      } catch (err) {
        if (err.response.data.error) {
          alert(err.response.data.error)
        } else {
          alert(err.response.data.errors[0].error)
        }
      } finally {
        this.isLoading = false
      }
    },
    async updateDelivery() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const sendData = new FormData()
      sendData.append('routeDate', moment(this.date).format('yyyy-MM-DD'))
      sendData.append('serviceID', this.service.id)
      sendData.append('pickUpID', this.pickup.id)
      sendData.append('dropOffID', this.dropOffs[0].dropOffName.id)
      sendData.append('billingID', this.billing.id)
      sendData.append('status', this.status ? 2 : 0)

      this.notes ? sendData.append('notes', this.notes) : null
      this.file ? sendData.append('attachment', this.file) : null
      try {
        await this.$store.dispatch('deliveries/update', {delivery: sendData, id: this.data.id})
        this.$root.$emit('ShowSidebar', null)
      } catch (err) {
        if (err.response.data.error) {
          alert(err.response.data.error)
        } else {
          alert(err.response.data.errors[0].error)
        }
      }
    },
    async asyncFind(query) {
      await this.$store.dispatch('customers/search', {'filters[businessName]': query, perPage: 200})
    },
    disableWeekends(date) {
      const day = new Date(date).getDay()
      return day === 0 || day === 6 || new Date().getTime() - 3600 * 1000 * 24 > date
    }
  },
  validations: {
    date: {required},
    service: {required},
    billing: {required},
    status: {required},
    pickup: {required},
    notes: {},
    dropOffs: {
      required,
      $each: {
        dropOffName: {required}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
