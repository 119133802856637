import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale'
import lang from 'element-ui/lib/locale/lang/en'
import MessagePlugin from '@/utils/message.plugin'
import AudioRecorder from 'vue-audio-recorder'

locale.use(lang)
import dateFilter from '@/filters/date.filter'
import dotsFilter from '@/filters/dots.filter'

import AudioVisual from 'vue-audio-visual'
Vue.use(AudioVisual)

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(ElementUI)

Vue.use(VueClipboard)
Vue.use(VueMask)
Vue.use(VModal)
Vue.use(Vuelidate)
Vue.use(MessagePlugin)
Vue.use(AudioRecorder)

Vue.filter('date', dateFilter)
Vue.filter('dots', dotsFilter)

Vue.prototype.$http = Axios
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

Vue.config.productionTip = false

Date.prototype.format = function() {
  var mm = this.getMonth() + 1
  var dd = this.getDate()
  return [this.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-')
}

Vue.prototype.$dots = function dots(text, length, isSpace = true) {
  if (!text) return null
  length ? null : (length = 20)
  if (text.length <= length) return text
  const textArray = text.split(' ')
  const sliced = text.slice(0, length)
  const slicedArray = sliced.split(' ')
  const lastElem = slicedArray.length - 1
  if (!isSpace) return sliced + '...'
  if (slicedArray[lastElem] === textArray[lastElem]) return sliced + '...'
  const lastSpace = sliced.lastIndexOf(' ')
  if (lastSpace && lastSpace !== -1) return sliced.slice(0, lastSpace) + '...'
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
