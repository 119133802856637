<template>
  <div class="n-select__wrapper">
    <div v-if="label" class="n-select__label" @click="showList">{{ label }}</div>
    <div class="n-select__tags" @click="showList">
      <div v-if="multiple" class="n-select__tags-wrap">
        <span v-for="(el, i) in formatValue" :key="i" class="n-select__tag">
          <span>{{ el }}</span>
          <svg
            @click.stop="deleteElement(el)"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.65313 3.97798L7.89313 0.737981C7.96594 0.652958 8.00399 0.543591 7.99967 0.431735C7.99535 0.319879 7.94898 0.213773 7.86983 0.134619C7.79067 0.0554663 7.68457 0.0090961 7.57271 0.00477558C7.46085 0.00045507 7.35149 0.0385024 7.26646 0.111314L4.02646 3.35131L0.786464 0.10687C0.701441 0.0340579 0.592074 -0.00398905 0.480218 0.00033146C0.368362 0.00465197 0.262256 0.0510217 0.183103 0.130175C0.10395 0.209328 0.0575794 0.315434 0.0532588 0.42729C0.0489383 0.539146 0.0869857 0.648513 0.159797 0.733536L3.3998 3.97798L0.155353 7.21798C0.108828 7.25783 0.0710408 7.30686 0.0443638 7.362C0.0176868 7.41714 0.00269541 7.47719 0.000331191 7.5384C-0.00203303 7.59961 0.00828072 7.66065 0.030625 7.71768C0.0529692 7.77471 0.0868618 7.82651 0.130175 7.86983C0.173488 7.91314 0.225287 7.94703 0.28232 7.96938C0.339354 7.99172 0.40039 8.00203 0.461599 7.99967C0.522807 7.99731 0.582866 7.98232 0.638006 7.95564C0.693146 7.92896 0.742176 7.89117 0.782019 7.84465L4.02646 4.60465L7.26646 7.84465C7.35149 7.91746 7.46085 7.95551 7.57271 7.95119C7.68457 7.94687 7.79067 7.9005 7.86983 7.82134C7.94898 7.74219 7.99535 7.63608 7.99967 7.52423C8.00399 7.41237 7.96594 7.30301 7.89313 7.21798L4.65313 3.97798Z"
            />
          </svg>
        </span>
      </div>
      <div v-else class="n-select__single">{{ formatValue }}</div>
      <div v-if="searchable" class="n-select__input"></div>
      <div v-if="!value.length || !value" class="n-select__placeholder">{{ placeholder }}</div>
      <div class="n-select__icon">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.34142 9.65858L9.65858 4.34142C9.78457 4.21543 10 4.30466 10 4.48284V9.8C10 9.91046 9.91046 10 9.8 10H4.48284C4.30466 10 4.21543 9.78457 4.34142 9.65858Z"
            fill="#0290FC"
          />
        </svg>
      </div>
    </div>
    <div v-show="show" class="n-select__content-wrap">
      <ul class="n-select__content">
        <li
          class="n-select__element"
          @click="selectAll"
          :class="{'n-select__element--active': all}"
        >
          <span
            class="n-select__element-checkbox"
            :class="{'n-select__element-checkbox--active': all}"
          >
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.57145 1.21423L3.58806 5.85709L1.42859 3.44042"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span class="n-select__element-name">All</span>
        </li>
        <li
          v-for="el in formatOptions"
          :key="el.id"
          class="n-select__element"
          :class="{'n-select__element--active': el.checked}"
          @click="updateValue(el)"
        >
          <span
            class="n-select__element-checkbox"
            :class="{'n-select__element-checkbox--active': el.checked}"
          >
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.57145 1.21423L3.58806 5.85709L1.42859 3.44042"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span class="n-select__element-name">{{ el.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Object, Array, Number],
      default: null
    },
    options: {
      type: Array,
      default: new Array(),
      required: true
    },
    searchable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data: () => ({
    show: false,
    all: false,
    formatValue: null,
    formatOptions: []
  }),
  computed: {
    multipleValue() {
      return this.formatOptions.filter(el => el.checked).map(el => el.name)
    }
  },
  watch: {
    value(value) {
      this.formatValue = value
    },
    show() {
      const list = this.$el.childNodes[2]
      const doc = this.$root.$el
      if (this.show) {
        setTimeout(() => {
          doc.addEventListener('click', outsideClickListener)
        }, 0)
      }
      function isVisible(list) {
        return (
          !!list &&
          !!(
            list.offsetWidth ||
            list.offsetHeight ||
            list.getClientRects().length
          )
        )
      }
      const outsideClickListener = event => {
        if (!this.show) {
          list.style = 'display: none'
          doc.removeEventListener('click', outsideClickListener)
        }
        if (!list.contains(event.target) && isVisible(list)) {
          this.show = false
          list.style = 'display: none'
          doc.removeEventListener('click', outsideClickListener)
        }
      }
    }
  },
  mounted() {
    this.formatOptions = this.options.map((option, i) => {
      return {
        name: option,
        checked: false,
        id: i
      }
    })
  },

  methods: {
    showList() {
      this.show = !this.show
    },
    updateValue(value) {
      if (this.multiple) {
        value.checked = !value.checked
        this.all = false
        this.$emit('input', this.multipleValue)
      } else {
        value.checked = !value.checked
        // this.$emit('input', value)
      }
    },
    deleteElement(el) {
      this.all = false
      this.formatOptions.find(option => option.name === el).checked = false
      this.$emit('input', this.multipleValue)
    },
    selectAll() {
      if (this.all) {
        this.formatOptions.forEach(el => (el.checked = false))
        this.all = false
      } else {
        this.formatOptions.forEach(el => (el.checked = true))
        this.all = true
      }
      this.$emit('input', this.multipleValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>

