import axios from 'axios'

export default {
  namespaced: true,

  state: {
    code: '',
    token: ''
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    }
  },
  actions: {
    async resetPasswordRequest({commit}, phone) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const req = await axios.post(url + `/users/password/reset`, {phone: phone})
        return req.data.result
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async verifyResetPasswordRequest({commit}, code) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const req = await axios.post(url + `/users/password/reset/verify/${code}`)
        commit('setToken', req.data.token)
        return req.data.token
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async resetPassword({commit, state}, password) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const req = await axios.post(url + `/users/password/reset/${state.token}`, password)
        return req.data.success
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  }
}
