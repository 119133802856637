import axios from 'axios'
import _ from 'lodash'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    orders: [],
    order: {},
    statuses: [],
    pagination: {},
    page: 1
  },
  mutations: {
    set(state, orders) {
      state.orders = _.unionBy(state.orders, orders, 'id')
    },
    setOrder(state, order) {
      state.order = order
    },
    updateOrder(state, order) {
      const orderPosition = state.orders.map(o => o.id).indexOf(order.id)
      state.orders[orderPosition] = order
    },
    setStatuses(state, statuses) {
      state.statuses = statuses
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.orders = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    },
    remove(state, id) {
      state.orders.splice(state.orders.map(order => order.id).indexOf(id), 1)
    }
  },
  actions: {
    async create({commit}, order) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.post(url + '/delivery-orders', order)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetch({commit, getters}, params) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const orders = await axios.get(url + '/delivery-orders/history', {
          params: {...params, page}
        })
        commit('set', orders.data[0])
        commit('pagination', orders.data[1])
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchOrder({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const order = await axios.get(url + '/delivery-orders/history/' + id)
        commit('setOrder', order.data)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchAdminOrder({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const order = await axios.get(url + '/delivery-orders/' + id)
        commit('setOrder', order.data)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchAdminOrders({commit, getters}, params) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const orders = await axios.get(url + '/delivery-orders/', {
          params: {...params, page}
        })
        commit('set', orders.data.deliveryOrders)
        commit('pagination', orders.data.pagination)
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async confirm({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.patch(url + `/delivery-orders/${id}/confirm`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async cancel({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.patch(url + `/delivery-orders/${id}/cancel`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async approve({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.patch(url + `/delivery-orders/${id}/approve`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async decline({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.patch(url + `/delivery-orders/${id}/decline`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchStatuses({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const statuses = await axios.get(url + '/delivery-orders/statuses')
        commit('setStatuses', statuses.data)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async delete({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.delete(url + `/delivery-orders/${id}/`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    orders: s => s.orders,
    statuses: s => s.statuses,
    pagination: s => s.pagination,
    page: s => s.page
  }
}
