<template>
  <div class="sidebar__main">
    <div class="sidebar__title">
      <span v-if="data">Edit - {{ data.name }}</span>
      <span v-else>Add New Shift</span>
    </div>
    <div class="sidebar__main-inner">
      <div class="sidebar__row sidebar__row--last">
        <div class="sidebar__row-title">Basic Data</div>
        <div class="sidebar__row-main">
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.name,
              'field__label--invalid': $v.sendData.name.$dirty && !$v.sendData.name.required
            }"
          >
            <div class="field__title">Shift Name</div>
            <input v-model="$v.sendData.name.$model" class="field__input" type="text" placeholder="Shift Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.dailyGoal,
              'field__label--invalid':
                ($v.sendData.dailyGoal.$dirty && !$v.sendData.dailyGoal.required) ||
                ($v.sendData.dailyGoal.$dirty && !$v.sendData.dailyGoal.numeric)
            }"
          >
            <div class="field__title">Daily Goal</div>
            <input
              v-model="$v.sendData.dailyGoal.$model"
              v-mask="'#####'"
              class="field__input"
              type="text"
              placeholder="Daily Goal"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.weeklyGoal,
              'field__label--invalid':
                ($v.sendData.weeklyGoal.$dirty && !$v.sendData.weeklyGoal.required) ||
                ($v.sendData.weeklyGoal.$dirty && !$v.sendData.weeklyGoal.numeric)
            }"
          >
            <div class="field__title">Weekly Goal</div>
            <input
              v-model="$v.sendData.weeklyGoal.$model"
              v-mask="'#####'"
              class="field__input"
              type="text"
              placeholder="Weekly Goal"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.monthlyGoal,
              'field__label--invalid':
                ($v.sendData.monthlyGoal.$dirty && !$v.sendData.monthlyGoal.required) ||
                ($v.sendData.monthlyGoal.$dirty && !$v.sendData.monthlyGoal.numeric)
            }"
          >
            <div class="field__title">Monthly Goal</div>
            <input
              v-model="$v.sendData.monthlyGoal.$model"
              v-mask="'#####'"
              class="field__input"
              type="text"
              placeholder="Monthly Goal"
            />
          </label>
          <div class="sidebar__row-title">Shift Color</div>
          <v-swatches v-model="sendData.color" inline></v-swatches>
        </div>
      </div>
    </div>

    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div class="button button--fill" @click="createShift">
          <span v-if="data">Save Changes</span>
          <span v-else>Create Shift</span>
        </div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, numeric} from 'vuelidate/lib/validators'
import VSwatches from 'vue-swatches'

export default {
  name: 'AddShift',
  components: {VSwatches},
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    sendData: {
      name: '',
      color: '#1FBC9C',
      dailyGoal: '',
      weeklyGoal: '',
      monthlyGoal: ''
    }
  }),
  created() {
    if (this.data) {
      this.sendData = Object.assign(this.sendData, this.data)
    }
  },
  methods: {
    async createShift() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const sendData = {
        name: this.sendData.name,
        color: this.sendData.color,
        dailyGoal: this.sendData.dailyGoal,
        weeklyGoal: this.sendData.weeklyGoal,
        monthlyGoal: this.sendData.monthlyGoal
      }
      try {
        if (this.data) {
          sendData.id = this.data.id
          await this.$store.dispatch('shifts/update', sendData)
        } else {
          await this.$store.dispatch('shifts/create', sendData)
        }
        this.$root.$emit('ShowSidebar', null)
      } catch (e) {
        if (e.response.data.error) {
          alert(e.response.data.error)
        } else {
          alert(e.response.data.errors[0].error)
        }
      }
    }
  },
  validations: {
    sendData: {
      name: {required},
      color: {required},
      dailyGoal: {required, numeric},
      weeklyGoal: {required, numeric},
      monthlyGoal: {required, numeric}
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
