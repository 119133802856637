<template>
  <div class="navbar__wrapper">
    <div class="container">
      <div class="navbar__inner">
        <div class="navbar__left">
          <div class="navbar__title">
            STS App
          </div>
        </div>
        <div class="navbar__right">
          <router-link to="/directory" class="button" active-class="button--fill">
            <i class="ri-user-2-fill"></i>
            Directory
          </router-link>
          <router-link to="/community" class="button" active-class="button--fill">
            <i class="ri-chat-3-fill"></i>
            Community
          </router-link>
          <router-link to="/public/new-order" class="button button--fill" :class="{disabled: disableBtn}">
            <i class="ri-map-pin-add-fill"></i>
            Book Order
          </router-link>
          <router-link to="/public/orders" class="button" active-class="button--fill">
            <i class="ri-compass-fill"></i>
            <span>My Orders</span>
          </router-link>
          <router-link to="/public/account" class="button" active-class="button--fill">
            <i class="ri-account-circle-fill"></i>
            <span>My Account</span>
          </router-link>
          <div class="navbar__notification">
            <el-dropdown trigger="click" placement="bottom" @command="openNotification">
              <div class="navbar__notification-button">
                <i class="ri-notification-2-fill"></i>
                <div v-if="notificatonCounter" class="navbar__notification-number">{{ notificatonCounter }}</div>
              </div>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--notifications">
                <el-dropdown-item v-if="!notifications.length" class="item__notification--missing">
                  No notifications 😴
                </el-dropdown-item>
                <el-dropdown-item
                  v-for="not of notifications"
                  :key="not.id"
                  class="item__notification"
                  :class="{'item__notification--active': !not.wasRead}"
                  :command="not"
                >
                  <div
                    class="dot"
                    :class="{
                      'dot--green': not.status === 'APPROVED' || !not.wasRead,
                      'dot--red': not.status === 'DECLINED',
                      'dot--yellow': (not.status === 'WaitingForPayment' && not.wasRead) || not.status === 'PENDING'
                    }"
                  ></div>
                  <div v-if="not.status === 'WaitingForPayment'" class="item__notification-text">
                    Please pay order {{ not.deliveryOrderID }}
                  </div>
                  <div v-else-if="not.status === 'PostComments'" class="item__notification-text">
                    New comment on your post {{ not.postID }}
                  </div>
                  <div v-else-if="not.status === 'NewBid'" class="item__notification-text">
                    New bid in your post {{ not.postID }}
                  </div>
                  <div v-else-if="not.status === 'BidAccepted'" class="item__notification-text">
                    Your bid was accepted in your post {{ not.postID }}
                  </div>
                  <div v-else class="item__notification-text">
                    Your order {{ not.deliveryOrderID }}
                    <span v-if="not.status === 'APPROVED'">approved</span>
                    <span v-else-if="not.status === 'DECLINED'">declined</span>
                  </div>
                  <span class="item__notification-date">{{ moment(not.date).format('D MMM h:mm a') }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="navbar__account">
            <div class="letter navbar__letter">{{ nameFirstLetter }}</div>
            <div class="navbar__account-info">
              <div class="navbar__account-title">
                <span>{{ userInfo.businessName }}</span>
                <span v-if="userInfo.nickName"> ({{ userInfo.nickName }})</span>
              </div>
              <div class="navbar__account-subtitle">
                <span>{{ userInfo.fathersName }}</span>
                <span> {{ userInfo.firstName }}</span>
                <span> {{ userInfo.lastName }}</span>
              </div>
            </div>
          </div>

          <div class="navbar__actions">
            <el-dropdown trigger="click" @command="action">
              <i class="ri-more-fill"></i>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                <el-dropdown-item class="item--edit" command="help">
                  <i class="ri-question-fill"></i> Help
                </el-dropdown-item>
                <el-dropdown-item class="item--edit" command="Logout">
                  <i class="ri-logout-circle-line"></i> Logout
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="navbar__mobile">
        <el-dropdown trigger="click" placement="bottom-start" @visible-change="showDropBg">
          <button class="icon-button">
            <i class="ri-menu-line"></i>
          </button>
          <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper-custom--nav">
            <el-dropdown-item>
              <router-link to="/directory" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-user-2-fill"></i>
                <span>Directory</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link to="/community" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-chat-3-fill"></i>
                <span>Community</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link to="/public/orders" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-map-pin-fill"></i>
                <span>My Orders</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link to="/public/account" class="nav__item--mobile" active-class="nav__item--active">
                <i class="ri-account-circle-fill"></i>
                <span>My Account</span>
              </router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <div v-if="path.name === 'PublicOrders'" class="navbar__title">Orders</div>
        <div v-else-if="path.name === 'OrderPage'" class="navbar__title"></div>
        <div v-else-if="path.name === 'DirectoryCustomer'" class="navbar__title"></div>
        <div v-else class="navbar__title">{{ path.name }}</div>
        <div class="navbar__notification">
          <el-dropdown trigger="click" placement="bottom" @command="openNotification">
            <div class="navbar__notification-button">
              <i class="ri-notification-2-fill"></i>
              <div v-if="notificatonCounter" class="navbar__notification-number">{{ notificatonCounter }}</div>
            </div>
            <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--notifications">
              <el-dropdown-item v-if="!notifications.length" class="item__notification--missing">
                No notifications 😴
              </el-dropdown-item>
              <el-dropdown-item
                v-for="not of notifications"
                :key="not.id"
                class="item__notification"
                :class="{'item__notification--active': !not.wasRead}"
                :command="not"
              >
                <div
                  class="dot"
                  :class="{
                    'dot--green': not.status === 'APPROVED' || !not.wasRead,
                    'dot--red': not.status === 'DECLINED',
                    'dot--yellow': (not.status === 'WaitingForPayment' && not.wasRead) || not.status === 'PENDING'
                  }"
                ></div>
                <div v-if="not.status === 'WaitingForPayment'" class="item__notification-text">
                  Please pay order {{ not.deliveryOrderID }}
                </div>
                <div v-else-if="not.status === 'PostComments'" class="item__notification-text">
                  New comment on your post {{ not.postID }}
                </div>
                <div v-else-if="not.status === 'NewBid'" class="item__notification-text">
                  New bid in your post {{ not.postID }}
                </div>
                <div v-else-if="not.status === 'BidAccepted'" class="item__notification-text">
                  Your bid was accepted in your post {{ not.postID }}
                </div>
                <div v-else class="item__notification-text">
                  Your order {{ not.deliveryOrderID }}
                  <span v-if="not.status === 'APPROVED'">approved</span>
                  <span v-else-if="not.status === 'DECLINED'">declined</span>
                </div>
                <span class="item__notification-date">{{ moment(not.date).format('D MMM h:mm a') }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-dropdown class="end" trigger="click" @command="action">
          <button class="icon-button icon-button--round">
            <i class="ri-more-fill"></i>
          </button>
          <el-dropdown-menu slot="dropdown" class="el-popper-custom">
            <el-dropdown-item class="item--edit" command="help">
              <i class="ri-question-fill"></i> Help
            </el-dropdown-item>
            <el-dropdown-item class="item--edit" command="Logout">
              <i class="ri-logout-circle-line"></i> Logout
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-if="showBg" class="mobile-bg"></div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import Pusher from 'pusher-js'
import MD5 from 'crypto-js/md5'
import moment from 'moment'
import Onboarding from '@/views/Client/Onboarding'

export default {
  name: 'NavbarClient',
  data: () => ({
    moment,
    name: '',
    letter: '',
    showBg: false,
    pusher: null
  }),
  computed: {
    path() {
      return this.$route
    },
    ...mapState({
      notifications: s => s.notifications.notifications,
      userInfo: s => s.client.info,
      viewPost: s => s.community.posts.post,
      hasBlock: s => s.operationStatus.hasBlock
    }),
    disableBtn() {
      if (!this.hasBlock?.isActive || this.hasBlock?.bypassed) {
        return false
      } else {
        return true
      }
    },
    notificatonCounter() {
      const counter = this.notifications.filter(n => !n.wasRead).length
      if (counter > 99) return '99+'
      return counter
    },
    nameFirstLetter() {
      return this.$store.getters.user.name ? this.$store.getters.user.name[0] : ''
    }
  },
  async created() {
    this.subscribe()
    await Promise.all([this.fetchUserInfo(), this.fetchNotifications(), this.getBlockInfo()])
  },
  beforeDestroy() {
    const userHash = MD5(this.userInfo.id?.toString()).toString()
    this.pusher.unsubscribe(userHash)
  },
  methods: {
    ...mapActions({
      fetchUserInfo: 'client/fetch',
      fetchNotifications: 'notifications/getNotifications',
      readNotification: 'notifications/readNotification',
      getBlockInfo: 'operationStatus/getBlockInfo'
    }),
    action(act) {
      if (act === 'Logout') {
        this.$store.dispatch('logout')
        this.$router.push('/login')
      } else if (act === 'help') {
        const mobile = document.documentElement.clientWidth <= 480
        let modalHeight = mobile ? {height: '100%'} : {height: 660}
        let modalWidth = mobile ? {width: '99.5%'} : {width: 480}
        this.$modal.display(
          Onboarding,
          {
            helpModal: true
          },
          {
            name: 'OnboardingModal',
            transition: 'none',
            overlayTransition: 'none',
            ...modalHeight,
            ...modalWidth,
            classes: 'modal modal-onboarding'
          }
        )
      }
    },
    async openNotification(notification) {
      if (!notification) return
      await this.readNotification(notification.id)
      if (
        notification.status === 'PostComments' ||
        notification.status === 'NewBid' ||
        notification.status === 'BidAccepted'
      ) {
        this.$router.push('/community/' + notification.postID)
      } else {
        this.$router.push('/public/orders/' + notification.deliveryOrderID)
      }
    },
    showDropBg() {
      this.showBg = !this.showBg
    },
    subscribe() {
      const pusherID = process.env.VUE_APP_PUSHER
      const pusher = (this.pusher = new Pusher(pusherID, {cluster: 'us2'}))
      const userHash = MD5(this.$store.getters.user.id?.toString()).toString()
      pusher.subscribe(userHash)
      pusher.bind('delivery-order.approved', data => {
        this.$store.commit('notifications/setApprovalNotification', data)
      })
      pusher.bind('delivery-order.declined', data => {
        this.$store.commit('notifications/setDeclinedNotification', data)
      })
      pusher.bind('delivery-order.waiting-for-payment', data => {
        this.$store.commit('notifications/setWaitingForPaymentNotification', data)
      })
      pusher.bind('post.new-comment', data => {
        this.$store.commit('notifications/setCommentsNotification', data)
      })
      pusher.bind('post.new-bid', data => {
        this.$store.commit('notifications/setNewBid', data)
        this.$store.commit('community/posts/changeBid', data)
        if (this.viewPost.id === data.postID) {
          this.$store.commit('community/auction/setCurrentBid', data.bid)
        }
      })
      pusher.bind('post.bid-accepted', data => {
        this.$store.commit('notifications/setBidAccepted', data)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
