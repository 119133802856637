import axios from 'axios'

export default {
  state: {
    mobile: false
  },
  mutations: {
    setDevice(state, check) {
      state.mobile = check
    }
  },
  actions: {
    async checkOnboarding({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/users/hide-tips/')
      } catch (err) {
        commit('setError', err)
        throw err
      }
    }
  },
  getters: {
    mobile: s => s.mobile
  }
}
