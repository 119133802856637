import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    services: [],
    service: {},
    pagination: {},
    page: 1
  },
  mutations: {
    set(state, services) {
      state.services = _.unionBy(state.services, services, 'id')
    },
    change(state, item) {
      if (typeof item === 'object') {
        state.services = state.services.map(service => {
          if (service.id === item.id) {
            return Object.assign(service, item)
          } else {
            return service
          }
        })
      } else if (typeof item === 'number') {
        const position = state.services.findIndex(sh => sh.id === item)
        state.services.splice(position, 1)
        state.pagination.total--
      }
    },
    setService(state, service) {
      state.service = service
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.services = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    }
  },
  actions: {
    async fetch({commit, getters}) {
      let page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const services = await axios.get(url + '/services/', {
          params: {page}
        })
        commit('pagination', services.data.pagination)
        commit('set', services.data.services)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async create({commit, dispatch}, service) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.post(url + '/services', service)
        commit('changePage', 1)
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async update({commit, dispatch}, service) {
      const id = service.id
      delete service.id
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + '/services/' + id, service)
        commit('reset')
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async activate({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/services/' + id + '/activate')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async deactivate({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/services/' + id + '/deactivate')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async delete({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.delete(url + '/services/' + id)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  },
  getters: {
    services: s => s.services,
    service: s => s.service,
    pagination: s => s.pagination,
    page: s => s.page
  }
}
