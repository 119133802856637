import axios from 'axios'

export default {
  namespaced: true,

  state: {
    surcharge: null
  },
  mutations: {
    set(state, surcharge) {
      state.surcharge = surcharge
    }
  },
  actions: {
    async fetch({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const surcharge = await axios.get(url + '/settings')
        commit('set', surcharge.data.fuelSurcharge)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async change({commit}, surcharge) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + '/settings/', surcharge)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  }
}
