import axios from 'axios'
import moment from 'moment'

export default {
  namespaced: true,

  state: {
    stats: {},
    weekStats: [],
    monthStats: [],
    yearStats: [],
    financeStats: []
  },
  mutations: {
    set(state, stats) {
      state.stats = stats
    },
    setWeekStats(state, stats) {
      state.weekStats = stats
    },
    setMonthStats(state, stats) {
      state.monthStats = stats
    },
    setYearStats(state, stats) {
      state.yearStats = stats
    },
    setFinanceStats(state, stats) {
      state.financeStats = stats
    }
  },
  actions: {
    async fetch({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const dashboard = await axios.get(url + '/dashboard')
        commit('set', dashboard.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchWeekStats({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const weekStats = await axios.get(url + '/dashboard/week')
        commit('setWeekStats', weekStats.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchMonthStats({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const monthStats = await axios.get(url + '/dashboard/month')
        commit('setMonthStats', monthStats.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchYearStats({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const yearStats = await axios.get(url + '/dashboard/year')
        commit('setYearStats', yearStats.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchFinanceStats({commit}, date) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const financeStats = await axios.get(url + `/dashboard/finance?from=${date.from}&to=${date.till}`)
        commit('setFinanceStats', financeStats.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  },
  getters: {
    weekStats: state => {
      const week = []
      week.length = 7
      week.fill(0)
      let day = 'Monday'
      let total = {}

      const stats = state.weekStats
      stats.forEach(d => {
        const date = d.label.slice(6, 10) + '-' + d.label.slice(0, 2) + '-' + d.label.slice(3, 5)
        day = moment(date).format('dddd')
        total = d.total
        switch (day) {
          case 'Monday':
            week[0] = total
            break
          case 'Tuesday':
            week[1] = total
            break
          case 'Wednesday':
            week[2] = total
            break
          case 'Thursday':
            week[3] = total
            break
          case 'Friday':
            week[4] = total
            break
          case 'Saturday':
            week[5] = total
            break
          case 'Sunday':
            week[6] = total
            break
        }
      })

      return week
    },
    monthStats: state => {
      const month = []
      month.length = 4
      month.fill(0)
      let total = {}

      const stats = state.monthStats
      stats.forEach(w => {
        const dateString = w.label.slice(0, 10)
        const date = dateString.slice(6, 10) + '-' + dateString.slice(0, 2) + '-' + dateString.slice(3, 5)
        const prefixes = [1, 2, 3, 4, 5]
        const weekNumber = prefixes[0 | (moment(date).date() / 7)]
        total = w.total

        switch (weekNumber) {
          case 1:
            month[0] = total
            break
          case 2:
            month[1] = total
            break
          case 3:
            month[2] = total
            break
          case 4:
            month[3] = total
            break
        }
      })
      return month
    },
    yearStats: state => {
      const year = []
      year.length = 12
      year.fill(0)
      let total = {}

      const stats = state.yearStats
      stats.forEach(m => {
        total = m.total

        switch (m.label) {
          case 'January':
            year[0] = total
            break
          case 'February':
            year[1] = total
            break
          case 'March':
            year[2] = total
            break
          case 'April':
            year[3] = total
            break
          case 'May':
            year[4] = total
            break
          case 'June':
            year[5] = total
            break
          case 'July':
            year[6] = total
            break
          case 'August':
            year[7] = total
            break
          case 'September':
            year[8] = total
            break
          case 'October':
            year[9] = total
            break
          case 'November':
            year[10] = total
            break
          case 'December':
            year[11] = total
            break
        }
      })
      return year
    },
    financeStats: state => state.financeStats
  }
}
