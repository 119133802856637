import axios from 'axios'

export default {
  namespaced: true,

  state: {
    currentBid: null,
    bidsList: null
  },
  mutations: {
    setCurrentBid(state, bid) {
      state.currentBid = bid
    },
    setBids(state, bids) {
      state.bidsList = bids
    },
    pushBid(state, bid) {
      state.bidsList.unshift(bid)
    }
  },
  actions: {
    async makeBid({commit}, {id, bid}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.post(url + `/community/auctions/${id}/bids`, {bid})
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async approveBid({commit}, {id, postID}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.post(url + `/community/auctions/${postID}/bids/${id}/approve`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchCurrentBid({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const currentBid = await axios.get(url + `/community/auctions/${id}/current-bid`)
        commit('setCurrentBid', currentBid.data.currentBid)
        return currentBid.data.currentBid
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchBids({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const bids = await axios.get(url + `/community/auctions/${id}/bids`)
        commit('setBids', bids.data)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  }
}
