import axios from 'axios'

export default {
  namespaced: true,

  state: {
    cards: [],
    activeCard: {}
  },
  mutations: {
    set(state, cards) {
      state.cards = cards
    },
    activate(state, card) {
      const cards = state.cards
      cards.forEach(c => (c.active = false))
      state.activeCard = null
      if (!card) return
      const activeCard = cards.find(c => c.paymentID === card.paymentID)
      activeCard.active = true
      state.activeCard = activeCard
    },
    reset(state) {
      state.cards = []
      state.activeCard = {}
    }
  },
  actions: {
    async fetch({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const cards = await axios.get(url + '/users/payment')
        const changedCards = cards.data
        changedCards.forEach(card => (card.active = false))
        commit('set', changedCards)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async add({commit, dispatch}, token) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + '/users/payment', token)
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async delete({commit, dispatch}, pm) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.delete(url + '/users/payment/' + pm)
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  },
  getters: {
    cards: s => s.cards
  }
}
