import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    posts: [],
    pinnedPosts: [],
    post: {},
    pagination: {},
    page: 1,
    // FILTER PARAMS
    filterParamsLocal: {},
    filterParamsSend: {},
    filterParamsOriginal: {
      type: null
    }
  },
  mutations: {
    set(state, posts) {
      state.posts = _.unionBy(state.posts, posts, 'id')
    },
    setPost(state, post) {
      state.post = post
    },
    setPinnedPosts(state, posts) {
      state.pinnedPosts = posts
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.posts = []
      state.page = 1
    },
    resetPinnedPosts(state) {
      state.pinnedPosts = []
    },
    changePage(state, page) {
      page ? (state.page = page) : state.page++
    },
    markAsSold(state, {postID, bid}) {
      const post = state.posts.find(p => p.id === postID)
      if (post) {
        post.postType = 'sold'
        post.soldFor = bid
      }
    },
    markAsSoldPost(state) {
      const post = state.post
      post ? (post.postType = 'sold') : null
    },
    changeBid(state, bid) {
      const post = state.posts.find(p => p.id === bid.postID)
      post ? (post.currentBid = bid.bid) : null
    },
    // FILTER PARAMS
    setFilterParamsLocal(state, params) {
      state.filterParamsLocal = params
    },
    setFilterParamsSend(state, params) {
      state.filterParamsSend = params
    },
    resetFilterParams(state) {
      state.filterParamsLocal = null
      state.filterParamsSend = null
    }
  },
  actions: {
    async create({commit}, post) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.post(url + '/community/posts', post)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetch({commit, state}, params) {
      const page = state.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const posts = await axios.get(url + '/community/posts', {
          params: {...params, ...state.filterParamsSend, page}
        })
        commit('set', posts.data.posts)
        commit('pagination', posts.data.pagination)
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchPost({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const post = await axios.get(url + '/community/posts/' + id)
        commit('setPost', post.data)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchPinnedPosts({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const posts = await axios.get(url + '/community/posts/pinned/')
        commit('setPinnedPosts', posts.data.posts)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async delete({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.delete(url + `/community/posts/${id}/`)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async update({commit}, {post, id}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.put(url + `/community/posts/${id}/`, post)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async markAsSold({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + `/community/posts/${id}/mark-as-sold`)
        commit('markAsSold', {postID: id})
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  }
}
