import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    comments: [],
    pagination: {},
    page: 1
  },
  mutations: {
    set(state, comments) {
      state.comments = _.unionBy(state.comments, comments, 'id')
    },
    add(state, comment) {
      state.comments.unshift(comment)
      state.pagination.total++
    },
    remove(state, id) {
      const formattedComments = state.comments.map(c => c.id)
      const indexComment = formattedComments.indexOf(id)
      state.comments.splice(indexComment, 1)
      state.pagination.total--
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.pagination = {}
      state.comments = []
      state.page = 1
    },
    changePage(state, page) {
      page ? (state.page = page) : state.page++
    }
  },
  actions: {
    async create({commit}, {comment, id}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.post(url + `/community/posts/${id}/comments`, comment)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetch({commit, state}, {params, id}) {
      const page = state.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const comments = await axios.get(url + `/community/posts/${id}/comments`, {
          params: {...params, page}
        })
        commit('set', comments.data.comments)
        commit('pagination', comments.data.pagination)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async delete({commit}, {id, postID}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.delete(url + `/community/posts/${postID}/comments/${id}/`)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async update({commit}, {postID, id, comment}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.put(url + `/community/posts/${postID}/comments/${id}/`, comment)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  }
}
