<template>
  <div class="sidebar__wrapper" @click.self="dialog = true">
    <div class="sidebar__inner" :style="sidebarStyles" :class="{'sidebar__inner--left': sidebarPosition === 'left'}">
      <component :is="componentName" :data="componentData" />
    </div>
    <el-dialog
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialog"
      :modal="false"
      custom-class="dialog-close"
      width="200px"
    >
      <span>Close sidebar ?</span>
      <div class="dialog__buttons">
        <el-button @click="$root.$emit('ShowSidebar', null)" size="small">Yes</el-button>
        <el-button @click="dialog = false" type="primary" size="small">No</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddDriver from '@/views/Admin/Drivers/AddDriver'
import AddShift from '@/views/Admin/Shifts/AddShift'
import OperationStatus from '@/components/OperationStatus'
import AddService from '@/views/Admin/Services/AddService'
import AddCustomer from '@/views/Admin/Customers/AddCustomer'
import AddDelivery from '@/views/Admin/Deliveries/AddDelivery'
import AddDeliveryMobile from '@/views/Admin/Deliveries/AddDeliveryMobile'
import AddCompany from '@/views/SuperAdmin/Companies/AddCompany'
import AddClient from '@/views/SuperAdmin/Clients/AddClient'
import MapParams from '@/views/Admin/Map/MapParams'
import MapFilter from '@/views/Admin/Map/MapFilter'
import MapDelivery from '@/views/Admin/Map/MapDelivery'
import AddDataExport from '@/views/Admin/Deliveries/ExportDeliveries/AddDataExport'
export default {
  name: 'Sidebar',
  components: {
    AddDriver,
    AddShift,
    AddService,
    AddCustomer,
    MapParams,
    MapFilter,
    MapDelivery,
    AddCompany,
    AddDelivery,
    AddClient,
    AddDeliveryMobile,
    AddDataExport,
    OperationStatus
  },
  props: {
    componentName: {
      type: String,
      default: null
    },
    componentStyles: {
      type: Object,
      default: null
    },
    componentData: {
      type: Object,
      default: null
    },
    sidebarStyles: {
      type: String,
      default: null
    },
    sidebarPosition: {
      type: String,
      default: 'right'
    }
  },
  data() {
    return {
      dialog: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
