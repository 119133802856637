import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    customers: [],
    customer: {},
    pagination: {},
    page: 1
  },
  mutations: {
    set(state, customers) {
      state.customers = _.unionBy(state.customers, customers, 'id')
    },
    setCustomer(state, customer) {
      state.customer = customer
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.customers = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    }
  },
  actions: {
    async fetch({commit, getters}, params) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const customers = await axios.get(url + '/customers/search-public', {
          params: {...params, page, perPage: 2400}
        })
        const changedCustomers = customers.data.result[0]
        changedCustomers.forEach(cus => (cus.active = false))
        commit('pagination', customers.data.result[1])
        commit('set', changedCustomers)
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchCustomer({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const customer = await axios.get(url + '/users/profile/customer')
        commit('setCustomer', customer.data)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  },
  getters: {
    customers: s => s.customers,
    customer: s => s.customer,
    idCustomer: s => s.customer.id,
    pagination: s => s.pagination,
    page: s => s.page
  }
}
