<template>
  <div class="map__wrapper">
    <div ref="map" class="map__inner"></div>
    <button class="button button--fill" @click="$modal.hide('Map')">Save Coords</button>
  </div>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader'

const loader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  mapIds: ['de2da045eb64b7a']
})

export default {
  name: 'Map',
  data() {
    return {
      isLoading: true,
      error: null,
      map: null
    }
  },
  computed: {
    isError() {
      return this.error !== null
    }
  },
  async mounted() {
    await this.loadMap()
  },
  methods: {
    async loadMap() {
      await loader.load()
      this.map = new google.maps.Map(this.$refs.map, {
        center: {
          lat: 43.716317,
          lng: -80.603254
        },
        zoom: 7,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        mapTypeId: 'hybrid'
      })
      let marker = new google.maps.Marker({
        position: {
          lat: 43.716317,
          lng: -80.603254
        },
        map: this.map,
        draggable: true,
      })
      marker.addListener('dragend', e => {
        this.$root.$emit('setMapParams', e.latLng.toJSON())
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
