<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import LoginLayout from '@/views/Login/layout'
import InviteLayout from '@/views/Invite/layout'
import mainLayout from '@/layouts/mainLayout'
import adminLayout from '@/layouts/adminLayout'
import superAdminLayout from '@/layouts/superAdminLayout'
import clientLayout from '@/layouts/clientLayout'
import enterpriseLayout from '@/layouts/enterpriseLayout'
import axios from 'axios'

export default {
  components: {
    mainLayout,
    clientLayout,
    enterpriseLayout,
    adminLayout,
    superAdminLayout,
    LoginLayout,
    InviteLayout
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'main') + '-layout'
    }
  },
  created() {
    const mobile = document.documentElement.clientWidth <= 768
    this.$store.commit('setDevice', mobile)
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
    if (!this.$store.getters.user?.role && this.$store.getters.token) {
      const user = JSON.parse(atob(localStorage.getItem('token').split('.')[1]))
      this.$store.commit('setUser', user)
    }
    this.$modal.open = function() {
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = 'fixed'
    }
    this.$modal.close = function() {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }

    this.$modal.display = function(component, component_properties, modal_properties, modal_events) {
      this.$modal.show(
        component,
        component_properties,
        {...this.$modal.styles, ...modal_properties},
        {'before-open': this.$modal.open, 'before-close': this.$modal.close, ...modal_events}
      )
    }.bind(this)
    this.$modal.styles = {
      classes: 'modal',
      adaptive: true
    }
    // ====================
    // Function that checks all requests
    // ====================
    axios.interceptors.response.use(undefined, err => {
      return new Promise(() => {
        if (
          (err.response.status === 419 || err.response.status === 401) &&
          err.config &&
          !err.config.__isRetryRequest
        ) {
          this.$store.dispatch('logout')
          this.$router.go('/login')
        }
        throw err
      })
    })
    // ====================
  }
}
</script>

<style lang="scss">
@import './assets/styles/style.scss';
</style>
