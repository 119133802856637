import axios from 'axios'
import _ from 'lodash'
import deliveries from './deliveries'
import enterprise from './enterprise'

export default {
  namespaced: true,

  state: {
    customers: [],
    searchCustomers: [],
    customer: {},
    pagination: {},
    page: 1
  },
  mutations: {
    set(state, customers) {
      state.customers = _.unionBy(state.customers, customers, 'id')
    },
    setSearch(state, customers) {
      customers.forEach(cus => (cus.active = false))
      state.searchCustomers = customers
    },
    change(state, item) {
      if (typeof item === 'object') {
        state.customers = state.customers.map(customer => {
          if (customer.id === item.id) {
            return _.assign(customer, item, item.customerInfo)
          } else {
            return customer
          }
        })
      } else if (typeof item === 'number') {
        const position = state.customers.findIndex(sh => sh.id === item)
        state.customers.splice(position, 1)
        state.pagination.total--
      }
    },
    setCustomer(state, customer) {
      state.customer = customer
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.customers = []
      state.page = 1
    },
    resetSearchedCustomer(state) {
      state.searchCustomers = []
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    }
  },
  actions: {
    async fetch({commit, getters}, params) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const customers = await axios.get(url + '/customers/', {
          params: {...params, page, perPage: 24}
        })
        commit('pagination', customers.data.result[1])
        commit('set', customers.data.result[0])
        commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchCustomer({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const customer = await axios.get(url + '/customers/' + id + '/')
        commit('setCustomer', customer.data.result)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async search({commit}, params) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const customers = await axios.get(url + '/customers/search/', {
          params: {...params, perPage: 4200}
        })
        commit('setSearch', customers.data.result[0])
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async create({commit, dispatch}, customer) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.post(url + '/customers', customer)
        commit('changePage', 1)
        await dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async update({commit, dispatch}, customer) {
      const id = customer.id
      delete customer.id
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + '/customers/' + id, customer)
        customer.id = id
        commit('reset')
        dispatch('fetch')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async activate({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/customers/' + id + '/activate')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async deactivate({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.patch(url + '/customers/' + id + '/deactivate')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async delete({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.delete(url + '/customers/' + id)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  },
  getters: {
    customers: s => s.customers,
    customer: s => s.customer,
    pagination: s => s.pagination,
    page: s => s.page,
    searchCustomers: s => s.searchCustomers
  },
  modules: {deliveries, enterprise}
}
