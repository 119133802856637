export default function date(value) {
  if (value) {
    let d = new Date(Date.parse(value)),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [day, month, year].join('.')
  } else {
    return null
  }
}
