import axios from 'axios'

export default {
  namespaced: true,

  state: {
    markers: [],
    delivery: {}
  },
  mutations: {
    setDelivery(state, delivery) {
      state.delivery = delivery
    },
    setMarkers(state, params) {
      state.markers = params
    },
    setMap(state, map) {
      state.map = map
    }
  },
  actions: {
    async fetchDelivery({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const delivery = await axios.get(url + '/deliveries/' + id)
        commit('setDelivery', delivery.data.delivery)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async fetchMarkers({commit}) {
      try {
        commit('setMarkers', [])
        const url = process.env.VUE_APP_BACKEND
        const {data} = await axios.get(url + '/deliveries/map')
        commit('setMarkers', data.result)
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    },
    async deliverableDone({commit, getters}, deliverableID) {
      const delivery = getters.delivery
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.patch(`${url}/deliverables/${deliverableID}/done`)
        delivery.customers.forEach(c => {
          if (c.id === deliverableID) {
            Object.assign(c, {status: true})
          }
        })
        if (!delivery.customers.find(c => !c.status)) {
          delivery.status = true
        }
        commit('setDelivery', delivery)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    delivery: s => s.delivery,
    markers: s => s.markers
  }
}
