import axios from 'axios'
// import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    customers: [],
    customer: {},
    pagination: {},
    page: 1
  },
  mutations: {
    set(state, customers) {
      // state.customers = _.unionBy(state.customers, customers, 'id')
      state.customers = customers
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.customers = []
      state.page = 1
    },
    changePage(state, page) {
      if (page) {
        state.page = page
      } else {
        state.page++
      }
    }
  },
  actions: {
    async fetch({commit, getters}, params) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const customers = await axios.get(url + '/customers/search-enterprise/', {
          params: {...params, page, perPage: 2400}
        })
        commit('pagination', customers.data.result[1])
        commit('set', customers.data.result[0])
        // commit('changePage')
      } catch (e) {
        commit('setError', e, {root: true})
        throw e
      }
    }
  }
}
