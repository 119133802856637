<template>
  <section class="new-order__part">
    <div v-if="value" class="new-order-files__item">
      <span
        class="new-order-file__type"
        :class="{
          'new-order-file__type--jpeg': fileData.type === 'jpeg',
          'new-order-file__type--png': fileData.type === 'png'
        }"
      >
        {{ fileData.type }}
      </span>
      <div class="new-order-file__info">
        <div class="new-order-file__name">{{ $dots(fileData.name, 20, false) }}</div>
        <span class="new-order-file__date">{{ fileData.date }}</span>
        <div class="new-order-file__dot"></div>
        <span class="new-order-file__size">{{ fileData.size }} Mb</span>
      </div>

      <div class="icon-button icon-button--round" @click="del">
        <i class="ri-delete-bin-fill"></i>
      </div>
    </div>
    <label v-else id="dropbox" class="new-order-files__label">
      <input class="new-order-files__input" type="file" accept="image/*" @change="handleFileUpload" />
      <i class="ri-image-fill"></i>
      <span class="new-order-files__text">
        Click here or drag file to upload
      </span>
    </label>
  </section>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FilesUploadComponent',
  props: {
    value: {
      type: File,
      default: null
    }
  },
  computed: {
    fileData() {
      const file = this.value
      const fileInfo = {
        type: file.type.split('/')[1],
        name: file.name,
        size: (file.size / Math.pow(2, 20)).toFixed(1),
        date: `${moment().format('DD MMM, YYYY')} at ${moment().format('HH:mm')}`
      }
      return fileInfo
    }
  },
  mounted() {
    this.drop()
  },
  methods: {
    handleFileUpload(e) {
      this.$emit('input', e.target.files[0])
    },
    drop() {
      const dropbox = document.getElementById('dropbox')
      dropbox.addEventListener('dragenter', dragenter, false)
      dropbox.addEventListener('dragover', dragover, false)
      dropbox.addEventListener('drop', drop, false)
      function dragenter(e) {
        e.stopPropagation()
        e.preventDefault()
      }
      function dragover(e) {
        e.stopPropagation()
        e.preventDefault()
      }
      function drop(e) {
        e.stopPropagation()
        e.preventDefault()
        this.$emit('input', e.target.files[0])
      }
    },
    del() {
      this.$emit('input', null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import './style.scss';
</style>
