import searchPickup from './searchPickup'
import searchDropOff from './searchDropOff'
import searchBilling from './searchBilling'

const getDefaultState = () => {
  return {
    pickup: null,
    dropOff: null,
    billing: null,
    employee: null,
    service: null,
    date: null,
    card: null,
    note: null,
    file: null
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),
  mutations: {
    setPickup(state, cus) {
      state.pickup = cus
    },
    setDropOff(state, cus) {
      state.dropOff = cus
    },
    setBilling(state, cus) {
      state.billing = cus
    },
    setEmployee(state, cus) {
      state.employee = cus
    },
    setService(state, service) {
      state.service = service
    },
    setDate(state, date) {
      state.date = date
    },
    setNote(state, note) {
      state.note = note
    },
    setFile(state, file) {
      state.file = file
    },
    reset(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {},
  getters: {
    pickup: s => s.pickup,
    dropOff: s => s.dropOff,
    billing: s => s.billing,
    employee: s => s.employee,
    service: s => s.service,
    date: s => s.date,
    card: s => s.card,
    note: s => s.note,
    file: s => s.file
  },
  modules: {
    searchPickup,
    searchDropOff,
    searchBilling
  }
}
