<template>
  <div class="sidebar__main">
    <div class="sidebar__title">
      <span v-if="data">Edit - {{ data.name }}</span>
      <span v-else>Add New Company</span>
    </div>
    <div class="sidebar__main-inner">
      <div class="sidebar__row">
        <div class="sidebar__row-title">Basic Info</div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.name,
              'field__label--invalid': $v.sendData.name.$dirty && !$v.sendData.name.required
            }"
          >
            <div class="field__title">Name</div>
            <input v-model="$v.sendData.name.$model" class="field__input" type="text" placeholder="Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.phone,
              'field__label--invalid':
                ($v.sendData.phone.$dirty && !$v.sendData.phone.required) ||
                ($v.sendData.phone.$dirty && !$v.sendData.phone.phoneReg)
            }"
          >
            <div class="field__title">Phone</div>
            <input
              v-model="$v.sendData.phone.$model"
              v-mask="'### ### ####'"
              class="field__input"
              type="tel"
              placeholder="Phone"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.address,
              'field__label--invalid': $v.sendData.address.$dirty && !$v.sendData.address.required
            }"
          >
            <div class="field__title">Address</div>
            <input v-model="$v.sendData.address.$model" class="field__input" type="text" placeholder="Phone" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.city,
              'field__label--invalid': $v.sendData.city.$dirty && !$v.sendData.city.required
            }"
          >
            <div class="field__title">City</div>
            <input v-model="$v.sendData.city.$model" class="field__input" type="text" placeholder="City" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.province,
              'field__label--invalid': $v.sendData.province.$dirty && !$v.sendData.province.required
            }"
          >
            <div class="field__title">Province</div>
            <input v-model="$v.sendData.province.$model" class="field__input" type="text" placeholder="Province" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.country,
              'field__label--invalid': $v.sendData.country.$dirty && !$v.sendData.country.required
            }"
          >
            <div class="field__title">Country</div>
            <input v-model="$v.sendData.country.$model" class="field__input" type="text" placeholder="Country" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.postalCode,
              'field__label--invalid': $v.sendData.postalCode.$dirty && !$v.sendData.postalCode.required
            }"
          >
            <div class="field__title">Postal Code</div>
            <input v-model="$v.sendData.postalCode.$model" class="field__input" type="tel" placeholder="Postal Code" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.hst,
              'field__label--invalid':
                ($v.sendData.hst.$dirty && !$v.sendData.hst.required) ||
                ($v.sendData.hst.$dirty && !$v.sendData.hst.minLength)
            }"
          >
            <div class="field__title">HST</div>
            <input
              v-model="$v.sendData.hst.$model"
              v-mask="'########'"
              class="field__input"
              type="text"
              placeholder="HST"
            />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.website && $v.sendData.website.url,
              'field__label--invalid': $v.sendData.website.$dirty && !$v.sendData.website.url
            }"
          >
            <div class="field__title">Website</div>
            <input v-model="$v.sendData.website.$model" class="field__input" type="text" placeholder="Website" />
          </label>
        </div>
      </div>
      <div v-if="!data" class="sidebar__row">
        <div class="sidebar__row-title">Contact Info</div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.admin.name,
              'field__label--invalid': $v.sendData.admin.name.$dirty && !$v.sendData.admin.name.required
            }"
          >
            <div class="field__title">Name</div>
            <input v-model="$v.sendData.admin.name.$model" class="field__input" type="text" placeholder="Name" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.admin.email,
              'field__label--invalid':
                ($v.sendData.admin.email.$dirty && !$v.sendData.admin.email.required) ||
                ($v.sendData.admin.email.$dirty && !$v.sendData.admin.email.email)
            }"
          >
            <div class="field__title">Email</div>
            <input v-model="$v.sendData.admin.email.$model" class="field__input" type="text" placeholder="Email" />
          </label>
          <label
            class="field__label"
            :class="{
              'field__label--filled': sendData.admin.password,
              'field__label--invalid':
                ($v.sendData.admin.password.$dirty && !$v.sendData.admin.password.required) ||
                ($v.sendData.admin.password.$dirty && !$v.sendData.admin.password.minLength)
            }"
          >
            <div class="field__title">Password</div>
            <input
              v-model="$v.sendData.admin.password.$model"
              class="field__input"
              type="text"
              placeholder="Password"
            />
          </label>
        </div>
      </div>
      <div class="sidebar__row sidebar__row--last">
        <div class="sidebar__row-title">Extras</div>
        <div class="sidebar__row-main sidebar__row-main--half">
          <label class="field__label field__label--sides">
            <div class="field__title">Fuel Surcharge</div>
            <el-switch v-model="sendData.fuelSuperCharge" />
          </label>
          <label class="field__label field__label--sides">
            <div class="field__title">Public Customers Access</div>
            <el-switch v-model="sendData.canAccessPublicCustomers" />
          </label>
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__footer-inner">
        <div class="button button--fill" @click="createCompanies">
          <span v-if="data">Save Changes</span>
          <span v-else>Create Company</span>
        </div>
        <div class="button" @click="$root.$emit('ShowSidebar', null)">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'
import {required, email, minLength, requiredIf, url, helpers} from 'vuelidate/lib/validators'
const phoneReg = helpers.regex('phoneReg', /^[2-9]\d{2} \d{3} \d{4}$/)

export default {
  name: 'AddCompany',
  components: {ToggleButton},
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    sendData: {
      fuelSuperCharge: false,
      name: '',
      phone: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
      hst: '',
      canAccessPublicCustomers: false,
      website: '',
      admin: {
        name: '',
        email: '',
        password: ''
      }
    }
  }),
  created() {
    if (this.data) {
      this.sendData = Object.assign(this.sendData, this.data)
    }
  },
  methods: {
    async createCompanies() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const sendData = {
        name: this.sendData.name,
        phone: this.sendData.phone,
        address: this.sendData.address,
        city: this.sendData.city,
        province: this.sendData.province,
        postalCode: this.sendData.postalCode,
        country: this.sendData.country,
        hst: this.sendData.hst,
        website: this.sendData.website,
        fuelSuperCharge: this.sendData.fuelSuperCharge,
        canAccessPublicCustomers: this.sendData.canAccessPublicCustomers,
        admin: {
          name: this.sendData.admin.name,
          email: this.sendData.admin.email,
          password: this.sendData.admin.password
        }
      }
      try {
        if (this.data) {
          delete sendData.admin
          sendData.id = this.data.id
          await this.$store.dispatch('companies/update', sendData)
        } else {
          await this.$store.dispatch('companies/create', sendData)
        }
        this.$root.$emit('ShowSidebar', null)
      } catch (e) {
        if (e.response.data.error) {
          alert(e.response.data.error)
        } else {
          alert(e.response.data.errors[0].error)
        }
      }
    }
  },
  validations: {
    sendData: {
      name: {required},
      phone: {required, phoneReg},
      address: {required},
      city: {required},
      province: {required},
      postalCode: {required},
      country: {required},
      hst: {required, minLength: minLength(8)},
      website: {url},
      fuelSuperCharge: {required},
      canAccessPublicCustomers: {required},
      admin: {
        required: requiredIf(function() {
          if (this.data) return true
        }),
        name: {
          required: requiredIf(function() {
            if (!this.data) return true
          })
        },
        email: {
          required: requiredIf(function() {
            if (!this.data) return true
          }),
          email
        },
        password: {
          required: requiredIf(function() {
            if (!this.data) return true
          }),
          minLength: minLength(8)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
