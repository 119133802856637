<template>
  <div class="modal__wrapper">
    <div class="modal__title">Change Fuel Surcharge</div>
    <form class="modal__form" @submit.prevent="changeSurcharge">
      <label class="field__label" :class="{'field__label--filled': surcharge}">
        <div class="field__title">Fuel Surcharge %</div>
        <input v-model.trim="localSurcharge" class="field__input" type="text" placeholder="Fuel Surcharge" />
      </label>
      <button type="submit" class="button button--fill modal__button">
        Change Fuel Surcharge
      </button>
    </form>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'FuelSurchargeModal',
  data: () => ({
    localSurcharge: null
  }),
  computed: {
    ...mapState({
      surcharge: s => s.surcharge.surcharge
    })
  },
  async created() {
    await this.fetch()
    this.localSurcharge = this.surcharge
  },
  methods: {
    ...mapActions({
      change: 'surcharge/change',
      fetch: 'surcharge/fetch'
    }),
    async changeSurcharge() {
      if (!this.localSurcharge) return
      await this.change({fuelSurcharge: this.localSurcharge})
      this.$modal.hide('FuelSurchargeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
