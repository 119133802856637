import axios from 'axios'

export default {
  namespaced: true,

  state: {
    info: {}
  },
  mutations: {
    set(state, info) {
      state.info = info
    }
  },
  actions: {
    async fetch({commit}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const info = await axios.get(url + '/users/profile/customer')
        commit('set', info.data)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async update({commit}, info) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.put(url + '/users/profile', info)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async updatePassword({commit}, pass) {
      try {
        const url = process.env.VUE_APP_BACKEND
        return await axios.put(url + '/users/password', pass)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  }
}
